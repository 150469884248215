import React from 'react';
import IconListItem from '../../../shared/IconListItem';
import { withBlock } from 'src/components/shared/Block';

export interface IconListProps {}

const SupportMenu: React.SFC<IconListProps> = () => {
	const ContactIcon: React.FC = () => (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 510 408'>
			<path
				d='M459 0H51C23 0 0 22.9 0 51v306c0 28 23 51 51 51h408c28 0 51-23 51-51V51c0-28.1-23-51-51-51zm0 102L255 229.5 51 102V51l204 127.5L459 51v51z'
				fill='#665A7F'
			/>
		</svg>
	);

	const PhoneIcon: React.FC = () => (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 578.1 578.1'>
			<g>
				<g>
					<path
						d='M577.8,456.1c1.2,9.4-1.6,17.5-8.6,24.5l-81.4,80.8c-3.7,4.1-8.5,7.6-14.4,10.4c-5.9,2.9-11.7,4.7-17.4,5.5    c-0.4,0-1.6,0.1-3.7,0.3c-2,0.2-4.7,0.3-8,0.3c-7.8,0-20.3-1.3-37.6-4s-38.6-9.2-63.6-19.6c-25.1-10.4-53.6-26-85.4-46.8    c-31.8-20.8-65.7-49.4-101.6-85.7c-28.6-28.2-52.2-55.1-71-80.8c-18.8-25.7-33.9-49.5-45.3-71.3c-11.4-21.8-20-41.6-25.7-59.4    s-9.6-33-11.6-45.9s-2.9-22.9-2.4-30.3c0.4-7.3,0.6-11.4,0.6-12.2c0.8-5.7,2.7-11.5,5.5-17.4s6.3-10.7,10.4-14.4L98,8.8    c5.7-5.7,12.2-8.6,19.6-8.6c5.3,0,10,1.5,14.1,4.6s7.5,6.8,10.4,11.3l65.5,124.2c3.7,6.5,4.7,13.7,3.1,21.4s-5.1,14.3-10.4,19.6    l-30,30c-0.8,0.8-1.5,2.1-2.1,4s-0.9,3.4-0.9,4.6c1.6,8.6,5.3,18.4,11,29.4c4.9,9.8,12.4,21.7,22.6,35.8s24.7,30.3,43.5,48.7    c18.4,18.8,34.7,33.4,49,43.8c14.3,10.4,26.2,18.1,35.8,22.9s16.9,7.9,22,8.9l7.6,1.5c0.8,0,2.1-0.3,4-0.9c1.8-0.6,3.2-1.3,4-2.1    l34.9-35.5c7.3-6.5,15.9-9.8,25.7-9.8c6.9,0,12.4,1.2,16.5,3.7h0.6l118.1,69.8C571.1,441.2,576.2,448,577.8,456.1z'
						fill='#665A7F'
					/>
				</g>
			</g>
		</svg>
	);
	const SmileIcon: React.FC = () => (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 510 510'>
			<g>
				<g id='mood'>
					<path
						className='st0'
						d='M255,0C114.8,0,0,114.8,0,255s114.8,255,255,255s255-114.8,255-255S395.2,0,255,0z M344.2,153    c20.4,0,38.2,17.9,38.2,38.2s-17.9,38.2-38.2,38.2S306,211.6,306,191.2S323.9,153,344.2,153z M165.8,153    c20.4,0,38.2,17.9,38.2,38.2s-17.9,38.2-38.2,38.2s-38.2-17.9-38.2-38.2S145.4,153,165.8,153z M255,408    c-66.3,0-122.4-43.4-142.8-102h285.6C377.4,364.6,321.3,408,255,408z'
						fill='#665A7F'
					/>
				</g>
			</g>
		</svg>
	);

	const ContactUsByEmail: React.FC = () => (
    <>
      <p>
        If you have a support issue, please send an email to
        support@nexvortex.com Email is the most efficient way to reach us
        because it automatically opens up a ticket and allows you to include all
        the information necessary for one of our engineers to immediately begin
        researching the issue.
      </p>
      <p>In your email, please include the following information:</p>
      <p>
        - Your account number <br />
        - The best way to reach you <br />
        - A description of the problem (i.e. when it started and whether it is
        reproducible)
      	<br />
      </p>
    </>
	);

	const ContactUsByPhone: React.FC = () => (
    <>
      <p>
        To reach us by phone, please dial +1 855.639.6300.
      	<br />
      	<br />
        Please have the ticket number that you receive from the email you send
        to <a href='mailto:support@nexvortex.com'>support@nexvortex.com</a> when
        calling our support line. If you do not immediately reach a support
        engineer, you will be given the option to leave a voicemail. Please
        leave a message with your name, your number, and your ticket ID.
      </p>
    </>
	);

	const SupportHours: React.FC = () => (
    <>
      <p>
        We are available to answer your calls: <br />
        Monday - Friday 7 a.m. to 11 p.m. (EST) and <br />
        Saturday - Sunday 8.30 a.m. to 6.30 p.m. (EST).
      </p>
    </>
	);

	return (
		<div className='support-menu--icon-list p-5'>
			<IconListItem
				icon={() => <ContactIcon />}
				title='CONTACT US BY EMAIL'
				content={() => <ContactUsByEmail />}
			/>
			<IconListItem
				icon={() => <PhoneIcon />}
				title='CONTACT US BY PHONE'
				content={() => <ContactUsByPhone />}
			/>
			<IconListItem
				icon={() => <SmileIcon />}
				title='SUPPORT HOURS'
				content={() => <SupportHours />}
			/>
		</div>
	);
};

export default withBlock(SupportMenu);
