import React, { useEffect, useContext } from 'react';
import moment from 'moment';

// Import shared components.
import { withBlock } from 'src/components/shared/Block';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';
import { CurrentReportState } from 'src/components/shared/context/CurrentReport/exports.types';
import { useCallStatistics } from 'src/components/shared/hooks/useCallStatistics';
import { selectCallStatsReportWithSum } from 'src/components/shared/hooks/useCallStatistics/exports.selectors';
import { CallStatisticsArgs } from 'src/components/shared/hooks/useCallStatistics/exports.types';
import { useCallAverageBySegment } from 'src/components/shared/hooks/useCallAverageBySegment';
import { selectCallAverageReportWithSummary } from 'src/components/shared/hooks/useCallAverageBySegment/exports.selectors';
import { CallAverageBySegmentArgs } from 'src/components/shared/hooks/useCallAverageBySegment/exports.types';
import { usePoorCalls } from 'src/components/shared/hooks/usePoorCalls';
import { PoorCallsArgs } from 'src/components/shared/hooks/usePoorCalls/exports.types';
import { selectPoorCallsReport, selectPoorCallsDailySegmentTotal } from 'src/components/shared/hooks/usePoorCalls/exports.selectors';

// Import local components.
import AverageCallQuality from './AverageCallQuality';
import MarginalCalls from './MarginalCalls';
import GoodCalls from './GoodCalls';
import PoorCalls from './PoorCalls';
import PoorCallsResponsibleSegment from './PoorCallsResponsibleSegment';

// Import local styles.
import './styles.scss';

export interface VisibilityOverviewProps {
	accountId: number|string
}

const VisibilityStaticLegend: React.SFC<{}> = () => (
	<dl className="graphs__static-legend">
		<dt className="metric--good">Good</dt>
		<dd className="metric--good">&gt;3.8</dd>
		<dt className="metric--marginal">Marginal</dt>
		<dd className="metric--marginal">3.2 - 3.8</dd>
		<dt className="metric--poor">Poor</dt>
		<dd className="metric--poor">&lt;3.2</dd>
	</dl>
);

const VisibilityOverview: React.FC<VisibilityOverviewProps> = ({ accountId }) => {
	// Local hooks.
	const [callStats, fetchCallStats ] = useCallStatistics({accountId});
	const [callAverages, fetchCallAverages ] = useCallAverageBySegment({accountId});
	const [poorCalls, fetchPoorCalls] = usePoorCalls({accountId});

	// Local contexts.
	const { state: sharedReportSettings } = useContext(CurrentReportContext);
	const { dateFrom, dateTo, metricInterval} = sharedReportSettings as CurrentReportState;
	
	// Fetches call statistics for an Account Site for a given period.
	const updateCallStatistics = async (dateFrom: Date, dateTo: Date, metricInterval: number = 86400) => {
		const params: CallStatisticsArgs = {
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
			metricInterval
		};

		fetchCallStats(params);
	};

	// Fetches call average by segment for an Account Site for a given period.
	const updateCallAverageBySegment = async (dateFrom: Date, dateTo: Date, metricInterval: number = 86400) => {
		const params: CallAverageBySegmentArgs = {
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
			metricInterval
		};

		fetchCallAverages(params);
	};

	// Fetches poor calls for an Account Site for a given period.
	const updatePoorCalls = async (dateFrom: Date, dateTo: Date, metricInterval: number = 86400) => {
		const params: PoorCallsArgs = {
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
			metricInterval
		};

		fetchPoorCalls(params);
	};

	// Component lifecycle effects.
	useEffect(() => {
		
		// Retrieve call statistics.
		updateCallStatistics(dateFrom, dateTo, metricInterval);

		// Retrieve call averages by segment.
		updateCallAverageBySegment(dateFrom, dateTo, metricInterval);

		// Retrieve poor calls.
		updatePoorCalls(dateFrom, dateTo, metricInterval);

	}, [dateFrom, dateTo, metricInterval]);

	const callStatsReport = selectCallStatsReportWithSum(callStats);
	const callAveragesReport = selectCallAverageReportWithSummary(callAverages);
	const poorCallsReport = selectPoorCallsDailySegmentTotal(poorCalls);

	return (
		<div className="visibility-overview">
			<div className="visibility-overview__graphs">
				<VisibilityStaticLegend />
				<AverageCallQuality isLoading={callAverages.isLoading} data={callAveragesReport} dateFrom={dateFrom} dateTo={dateTo} />
				<GoodCalls isLoading={callStats.isLoading} data={callStatsReport} dateFrom={dateFrom} dateTo={dateTo} />
				<MarginalCalls isLoading={callStats.isLoading} data={callStatsReport} dateFrom={dateFrom} dateTo={dateTo} />
				<PoorCalls isLoading={callStats.isLoading} data={callStatsReport} dateFrom={dateFrom} dateTo={dateTo} />
				<PoorCallsResponsibleSegment isLoading={poorCalls.isLoading} data={poorCallsReport} dateFrom={dateFrom} dateTo={dateTo} />
			</div>
		</div>
	);
};

export default withBlock(VisibilityOverview, {
	header: {
		heading: 'Visibility',
		actions: [ <CurrentReportFilterForm siteFilter={{isVisible: false}} /> ]
	}
});
