import * as React from 'react';
import { Alert, Spinner } from 'reactstrap';

const BlockContentLoading: React.FC<{}> = () => (
	<div className="d-flex justify-content-center" style={{paddingTop: 40, paddingBottom: 40, marginTop: 20, marginBottom: 20 }}>
		<Alert color="primary">
			<div className="d-flex justify-content-between align-items-center">
				<Spinner color="primary" size="sm" className="mr-2" />
				<span><strong>Hang on...</strong> The results are currently loading and will update shortly.</span>
			</div>
		</Alert>
	</div>
);

export default BlockContentLoading;