import React, { useState } from 'react';
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './styles.scss';
import { Route } from 'react-router';
export interface QoSReportsNavigationToggleProps {
  currentPageTitle: string
}
export interface QosReportsNavigation {
  link: string,
  title: string
}

const QoSReportsNavigationToggle: React.FC<QoSReportsNavigationToggleProps> = ({
	currentPageTitle
}) => {

	const QosReportsNavigation: QosReportsNavigation[] = [
		{
			title: 'Average QoS',
			link: 'average-by-segment'
		},
		{
			title: 'QoS Statistics',
			link: 'call-statistics'
		},
		{
			title: 'QoS Drift',
			link: 'drift-report'
		},
		{
			title: 'Total Calls',
			link: 'total-calls'
		},
		{
			title: 'Poor - Week',
			link: 'poor-calls/day-of-week'
		},
		{
			title: 'Poor - Date',
			link: 'poor-calls/date'
		},
		{
			title: 'Poor - Time',
			link: 'poor-calls/time-of-day'
		},
		{
			title: 'Poor - Marginal',
			link: 'poor-and-marginal'
		}
	];
	
	const DropdownIcon: React.FC = () => (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.3 146.1'>
			<g fill='#FFFFFF'>
				<path
					d='M286.9,4.8C283.3,1.6,279,0,274.1,0H18.3c-5,0-9.2,1.6-12.9,4.8C1.8,8,0,11.8,0,16.2s1.8,8.2,5.4,11.4
   l127.9,113.7c3.6,3.2,7.9,4.8,12.9,4.8s9.2-1.6,12.8-4.8L286.9,27.6c3.6-3.2,5.4-7,5.4-11.4C292.4,11.8,290.5,8,286.9,4.8z'
				/>
			</g>
		</svg>
	);

	const [isDropdownOpen, toggleDropdown] = useState(false);

	return (
		<Route
			path='/secure/msip/:accountId/reports/qos'
			render={({ match }) => (
				<Dropdown
					className='dropdown--qos-reports-quicknav'
					isOpen={isDropdownOpen}
					toggle={() => toggleDropdown(!isDropdownOpen)}
				>
					<DropdownToggle
						caret={false}
						color="primary"
						className="dropdown-toggle"
					>
						<span>{currentPageTitle}</span>
					</DropdownToggle>
					<DropdownMenu right>
						{QosReportsNavigation.filter(
							item => item.title !== currentPageTitle
						).map(dropDownItem => (
							<Link key={dropDownItem.link} to={`${match.url}/${dropDownItem.link}`}>
								<DropdownItem>{dropDownItem.title}</DropdownItem>
							</Link>
						))}
					</DropdownMenu>
				</Dropdown>
			)}
		/>
	);
};

export default QoSReportsNavigationToggle;
