import * as React from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router';
import { Page } from '@nexvortex/page';
import {
	AvailabilityReportsPage,
	CallDriftPage,
	CallQosStatisticsPage,
	DashboardPage,
	LogsPage,
	PoorCallsByDayOfWeek,
	PoorCallsByDate,
	PoorCallsByTimeOfDay,
	PoorAndMarginalCallsPage,
	QOSReports,
	StatusReportsPage,
	Support,
	TotalCallsPage,
} from '../Pages';
import AverageQOSBySegment from '../Pages/AverageQOSBySegment';
import { CurrentReportProvider } from 'src/components/shared/context/CurrentReport';

export interface MSIPProps {}
 
const MSIP: React.FC<MSIPProps & RouteComponentProps> = ({ match }) => {

	React.useEffect(() => {
		// Perform checks here for the following items:
		// - Does the account exist (No - Redirect 404)
		// - Is the account an mSIP account (No - Redirect 404)
		// - Is user an administrative role (Yes - Render, No - Check account relationship)
		// - Is the non-administrative role associated with the account (Yes - Render)
	}, []);

	return (  
		<CurrentReportProvider>
			<Page
				strict
				path={`${match.path}/:accountId`}
				render={({ match }) => {
					return (
						<Switch>
							<Page strict exact path={`${match.path}/dashboard`} component={DashboardPage} />
							<Page strict exact path={`${match.path}/reports/availability`} component={AvailabilityReportsPage} />
							<Page strict exact path={`${match.path}/reports/qos`} component={QOSReports} />
							<Page strict exact path={`${match.path}/reports/qos/average-by-segment`} component={AverageQOSBySegment} />
							<Page strict exact path={`${match.path}/reports/qos/call-statistics`} component={CallQosStatisticsPage} />
							<Page strict exact path={`${match.path}/reports/qos/drift-report`} component={CallDriftPage} />
							<Page strict exact path={`${match.path}/reports/qos/total-calls`} component={TotalCallsPage} />
							<Page strict exact path={`${match.path}/reports/qos/poor-calls/day-of-week`} component={PoorCallsByDayOfWeek} />
							<Page strict exact path={`${match.path}/reports/qos/poor-calls/date`} component={PoorCallsByDate} />
							<Page strict exact path={`${match.path}/reports/qos/poor-calls/time-of-day`} component={PoorCallsByTimeOfDay} />
							<Page strict exact path={`${match.path}/reports/qos/poor-and-marginal`} component={PoorAndMarginalCallsPage} />
							<Page strict exact path={`${match.path}/reports/status`} component={StatusReportsPage} />
							<Page strict exact path={`${match.path}/logs`} component={LogsPage} />
							<Page strict exact path={`${match.path}/support`} component={Support} />
							<Redirect exact path={`${match.path}/reports`} to="reports/qos" />
							<Redirect exact path={`${match.path}`} to={`${match.path}/dashboard`} />
						</Switch>
					);
				}}
			/>
		</CurrentReportProvider>
	);

};
 
export default MSIP;