import React from 'react';
import IconBlockItem from '../../../shared/IconBlockItem';
import {
	AverageQOSBySegmentIcon,
	CallQOSStatisticsIcon,
	QOSDriftReportIcon,
	TotalCallsReportIcon,
	PoorCallsByDayOfWeekIcon,
	PoorCallsByDateIcon,
	PoorCallsByTimeOfDay,
	PoorMarginalCallsIcon
} from './QOSReportsMenuIcons';
import './styles.scss';

export interface QOSReportsMenuProps {}

const QOSReportsMenu: React.FC<QOSReportsMenuProps> = () => {
	return (
		<div className='qos-reports-menu-block'>
			<div className='qos-reports-menu-block--icons__row'>
				<IconBlockItem
					icon={() => <AverageQOSBySegmentIcon />}
					label='AVERAGE QOS BY SEGMENT'
					link="qos/average-by-segment"
				/>
				<IconBlockItem
					icon={() => <CallQOSStatisticsIcon />}
					label='CALL QOS STATISTICS'
					link="qos/call-statistics"
				/>
				<IconBlockItem
					icon={() => <QOSDriftReportIcon />}
					label='QOS DRIFT REPORT'
					link="qos/drift-report"
				/>
				<IconBlockItem
					icon={() => <TotalCallsReportIcon />}
					label='TOTAL CALLS REPORT'
					link="qos/total-calls"
				/>
			</div>
			<div className='qos-reports-menu-block--icons__row'>
				<IconBlockItem
					icon={() => <PoorCallsByDayOfWeekIcon />}
					label='POOR CALLS BY DAY OF WEEK'
					link="qos/poor-calls/day-of-week"
				/>
				<IconBlockItem
					icon={() => <PoorCallsByDateIcon />}
					label='POOR CALLS BY DATE'
					link="qos/poor-calls/date"
				/>
				<IconBlockItem
					icon={() => <PoorCallsByTimeOfDay />}
					label='POOR CALLS BY TIME OF DAY'
					link="qos/poor-calls/time-of-day"
				/>
				<IconBlockItem
					icon={() => <PoorMarginalCallsIcon />}
					label='POOR &amp; MARGINAL CALLS'
					link="qos/poor-and-marginal"
				/>
			</div>
		</div>
	);
};

export default QOSReportsMenu;
