import * as React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps, UncontrolledDropdown } from 'reactstrap';
import { ExportIcon } from '../icons';
import { CSVLink } from 'react-csv';
import { useState, useRef } from 'react';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';

import './ExportButton.scss';

export interface AddButtonProps extends ButtonProps {
	children?: string,
	reactTableRef?: React.RefObject<any>,
	headersCSV?: { label: string; key: string }[],
	filenameCSV?: string | undefined
}

const ExportButton: React.SFC<AddButtonProps> = ({
	className,
	title,
	'aria-label': ariaLabel,
	children,
	headersCSV,
	filenameCSV,
	reactTableRef = useRef(null),
	...props
}) => {

	type csvDataType = string | object[];
	type csvHeadersType = string[] | LabelKeyObject[] | undefined;
	type csvFilenameType = string | undefined;
	let [csvData, setCsvData] = useState<csvDataType>('');
	let [csvHeaders, setCsvHeaders] = useState<csvHeadersType>(undefined);
	let [csvFilename, setCsvFilename] = useState<csvFilenameType>('');

	return (
		<CSVLink data={csvData} headers={csvHeaders} filename={csvFilename}>
			<Button
				{...props}
				outline
				type='button'
				color="primary"
				className={classNames(className, 'action-export')}
				title={title || children}
				aria-label={ariaLabel || children}
				onClick={() => {
					reactTableRef.current ? setCsvData(reactTableRef.current.state.resolvedData) : setCsvData('');
					setCsvHeaders(headersCSV);
					setCsvFilename(filenameCSV);
				}}
			>
				<ExportIcon />
				<span>{children}</span>
			</Button>
		</CSVLink>);
};

export default ExportButton;