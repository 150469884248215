import React from 'react';

// Import shared local Components.
import { BlockContentLoadingAlert } from 'src/components/shared/Alerts';
import { ServiceSnapshot } from 'src/components/shared/ServiceSnapshot';
import { StackedMetricFieldProps } from 'src/components/shared/StackedMetricField/StackedMetricField';

export interface QualityOverviewSnapshotProps {
  isLoading: boolean,
  total: number,
	source: string,
	sourcePercentage: number
}

const QualityOverviewSnapshot: React.FC<QualityOverviewSnapshotProps> = ({
	isLoading,
	total,
	source,
	sourcePercentage
}) => {

	// Derives a theme color from the metric percentage.
	const getMetricColorClassName = (percent: number): StackedMetricFieldProps['color'] => {
		if (percent < 0.5) {
			return 'secondary';
		} else if (percent > 0.5 && percent < 1) {
			return 'warning';
		} else if (percent >= 1) {
			return 'danger'; 
		}
		return 'primary';
	};

	const formatSourcePercentage = (n: number) => {
		if (n === 0) { return n; }

		const p: number = n * 100;
		return p.toFixed(2);
	};

	const availabilitySnapshotMetrics: StackedMetricFieldProps[] = [
		{
			label: 'Poor Calls',
			value: total,
			percent: true,
			color: getMetricColorClassName(total),
			suffix: 'Last 30 days',
		},
		{
			label: 'Source',
			value: source,
			percent: false,
			color: 'primary',
			suffix: 'Poor Calls',
		},
		{
			label: 'Poor Calls',
			value: formatSourcePercentage(sourcePercentage),
			percent: true,
			color: 'primary',
			suffix: 'From Source',
		},
	];

	if (isLoading) {
		return (
			<BlockContentLoadingAlert />
		);
	}

	return (
		<ServiceSnapshot
			metrics={availabilitySnapshotMetrics}
			buttonLabel="QOS Reports"
			buttonLink="reports/qos"
		/>
	);
};

export default QualityOverviewSnapshot;