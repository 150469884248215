import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export interface StackedMetricFieldProps {
  label: string,
  value: string | number,
  suffix: string,
  percent?: boolean,
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'light' | 'dark',
  className?: string
}

const StackedMetricField: React.SFC<StackedMetricFieldProps> = ({
	className,
	color = 'primary',
	label,
	percent = false,
	suffix,
	value,
}) => {

	const defaultClassName = 'stacked-metric-field';

	const bar: string = label.split(' ').join('-').toLowerCase();

	const containerClassNames: string = classNames(defaultClassName, `${defaultClassName}--${bar}`, className ? className : '');

	const metricClassNames: string = classNames(`${defaultClassName}__metric`, `text-${color}`);

	const labelClassNames: string = classNames(`${defaultClassName}__label`, className ? `${className}__label`: '' );

	const suffixClassNames: string = classNames(`${defaultClassName}__suffix`, className ? `${className}__suffix`: '' );

	return (
		<div className={containerClassNames}>
			<div className={labelClassNames}>{label}</div>
			<div className={metricClassNames}>
				{ value }
				{percent && <sub>%</sub>}
			</div>
			<div className={suffixClassNames}>{suffix}</div>
		</div>
	);
};

export default StackedMetricField;
