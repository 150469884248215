import * as React from 'react';
import classNames from 'classnames';
import ReactTable, { TableProps } from 'react-table';

import { ReactComponent as FirstPage } from './first-page.svg';
import { ReactComponent as PrevPage } from './previous-page.svg';
import { ReactComponent as NextPage } from './next-page.svg';
import { ReactComponent as LastPage } from './last-page.svg';

import 'react-table/react-table.css';
import './data-table.scss';

// Copied from [react-table source](https://github.com/tannerlinsley/react-table/blob/v6/src/pagination.js)
class Pagination extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.getSafePage = this.getSafePage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.applyPage = this.applyPage.bind(this);

		this.state = {
			page: props.page
		};
	}

	componentWillReceiveProps(nextProps: any) {
		if (this.props.page !== nextProps.page) {
			this.setState({ page: nextProps.page });
		}
	}

	getSafePage(page: any) {
		if (Number.isNaN(page)) {
			page = this.props.page;
		}
		return Math.min(Math.max(page, 0), this.props.pages - 1);
	}

	changePage(page: any) {
		page = this.getSafePage(page);
		this.setState({ page });
		if (this.props.page !== page) {
			this.props.onPageChange(page);
		}
	}

	applyPage(e?: any) {
		if (e) {
			e.preventDefault();
		}
		const page = this.state.page;
		this.changePage(page === '' ? this.props.page : page);
	}

	getPageJumpProperties () {
		return {
			onKeyPress: (e: any) => {
				if (e.which === 13 || e.keyCode === 13) {
					this.applyPage();
				}
			},
			onBlur: this.applyPage,
			value: this.state.page === '' ? '' : this.state.page + 1,
			onChange: (e: any) => {
				const val = e.target.value;
				const page = val - 1;
				if (val === '') {
					return this.setState({ page: val });
				}
				this.setState({ page: this.getSafePage(page) });
			},
			inputType: this.state.page === '' ? 'text' : 'number',
			pageJumpText: this.props.pageJumpText,
		};
	}

	render () {
		const {
			// Computed
			pages,
			// Props
			page,
			pageSize,
			canPrevious,
			canNext,
			className
		} = this.props;

		const current = page;
		let list: any[] = [];
		if (canPrevious) {
			if (!canNext) { list = [ ...list, current - 2 ]; }
			list = [ ...list, current - 1];
		}
		list = [ ...list, current ];
		if (canNext) {
			list = [ ...list, current + 1 ];
			if (!canPrevious) { list = [ ...list, current + 2 ]; }
		}

		const info = this.props.data.length < pageSize
			? `${this.props.pageText} ${this.props.data.length} ${this.props.itemsText}`
			: `${this.props.pageText} ${
				(page * pageSize) + 1
			} - ${
				Math.min(((page + 1) * pageSize), this.props.data.length)
			} ${this.props.ofText} ${
				this.props.data.length
			} ${this.props.itemsText}`;

		// NOTE: Page size options was removed because pagination would not update along with changes in page size.
		return (
			<footer className={classNames(className, '-pagination')} style={this.props.style}>
				<p className="-pageInfo">{info}</p>
				<menu>
					<li>
						<button
							type='button'
							className='first'
							disabled={page === 0}
							onClick={() => page !== 0 && this.changePage(0)}
						>
							<FirstPage/>
							<span>First page</span>
						</button>
					</li>
					<li>
						<button
							type='button'
							className='prev'
							disabled={!canPrevious}
							onClick={() => canPrevious && this.changePage(page - 1)}
						>
							<PrevPage/>
							<span>{this.props.previousText}</span>
						</button>
					</li>
					{list.map((targetPage: number) => (
						<li key={targetPage}>
							<button
								type='button'
								className={classNames({ page: true, current: page === targetPage })}
								onClick={() => page !== targetPage && this.changePage(targetPage)}
							>
								{targetPage + 1}
							</button>
						</li>
					))}
					<li>
						<button
							type='button'
							className='next'
							disabled={!canNext}
							onClick={() => canNext && this.changePage(page + 1)}
						>
							<span>{this.props.nextText}</span>
							<NextPage/>
						</button>
					</li>
					<li>
						<button
							type='button'
							className='last'
							disabled={page === (pages - 1)}
							onClick={() => page !== (pages - 1) && this.changePage(pages - 1)}
						>
							<span>Last page</span>
							<LastPage/>
						</button>
					</li>
				</menu>
			</footer>
		);
	}
}

interface ReactTableRefProps { reactTableRef?: React.RefObject<any> }
type Props<D = any, ResolvedData = D> = Partial<TableProps<D, ResolvedData> & ReactTableRefProps>;

export const DataTable: React.FC<Props> = ({ className, reactTableRef, ...props }) => (
	<ReactTable
		className={classNames('nv', className)}
		pageSize={10}
		resizable={false}
		getPaginationProps={() => ({
			pageText: 'Showing',
			ofText: 'of',
			itemsText: 'items' // Added property
		})}
		PaginationComponent={Pagination}
		ref={reactTableRef}
		noDataText='Data unavailable or failed to load.'
		{...props}
	/>
);
