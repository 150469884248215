import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import moment from 'moment';

import { Block } from 'src/components/shared/Block';import { usePoorCalls } from 'src/components/shared/hooks/usePoorCalls';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';
import { selectPoorCallsReport } from 'src/components/shared/hooks/usePoorCalls/exports.selectors';
import { ExportButton } from 'src/components/shared/buttons';
import PoorCallsByDayOfWeekGraph from './PoorCallsByDayOfWeekGraph';
import PoorCallsByDayOfWeekDataTable from './PoorCallsByDayOfWeekDataTable';
import { withExportHandler, ExportHOCProps, poorCallsByDayOfWeekFilenameCSV, poorCallsByDayOfWeekHeadersCSV } from 'src/components/shared/ExportHandler';

export interface PoorCallsByDayOfWeekProps {
	accountId: string|number
}

const PoorCallsByDayOfWeek: React.FC<PoorCallsByDayOfWeekProps & ExportHOCProps> = ({
	accountId,
	reactTableRef = useRef(null)
}
) => {

	// Local custom Hooks.
	const [poorCalls, fetchPoorCalls, resetPoorCalls] = usePoorCalls({accountId});

	// Local contexts.
	const {state: {dateFrom, dateTo, metricInterval}} = React.useContext(CurrentReportContext);

	useEffect(() => {
		const fetchData = async () => {
			fetchPoorCalls({
				dateFrom: moment(dateFrom).toString(),
				dateTo: moment(dateTo).toString(),
				metricInterval: metricInterval,
				reportType: 'dayOfWeek',
			});
		};

		fetchData();
	}, [dateFrom, dateTo, metricInterval]);

	return (
		<Block
			header={{
				render: () =>
					<Container fluid className="p-0">
						<Row>
							<Col lg='12'>
								<Nav className="d-flex justify-content-end">
									<NavItem className="mr-3">
										<CurrentReportFilterForm siteFilter={{isVisible: false}} dateRangeSelector={{visibleOptions: ['lastMonth', 'lastWeek']}} />
									</NavItem>
									<NavItem>
										<ExportButton
											reactTableRef={reactTableRef}
											headersCSV={poorCallsByDayOfWeekHeadersCSV}
											filenameCSV={poorCallsByDayOfWeekFilenameCSV}>EXPORT CSV</ExportButton>
									</NavItem>
								</Nav>
							</Col>
						</Row>
					</Container>
			}}
		>
			<div className='poor-calls-by-day-of-week'>
				<PoorCallsByDayOfWeekGraph isLoading={poorCalls.isLoading} report={selectPoorCallsReport(poorCalls) || []} />
				<PoorCallsByDayOfWeekDataTable isLoading={poorCalls.isLoading} report={selectPoorCallsReport(poorCalls) || []} reactTableRef={reactTableRef} />
			</div>
		</Block>
	);
};

export default withExportHandler(PoorCallsByDayOfWeek);