import React, { useEffect } from 'react';
import moment from 'moment';

// Import local Components.
import { withBlock } from 'src/components/shared/Block/withBlock';
import { useTotalCalls } from 'src/components/shared/hooks/useTotalCalls';
import { selectTotalCallsReport, selectTotalCallsByTotal } from 'src/components/shared/hooks/useTotalCalls/exports.selectors';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';
import { TotalCallsGraph } from './TotalCallsGraph';
import { TotalCallsDataTable } from './TotalCallsDataTable';

// Import local styles.
import './styles.scss';

export interface TotalCallsProps {
	accountId: string | number
}

const TotalCalls: React.SFC<TotalCallsProps> = ({accountId}) => {
	// Local Custom Hooks.
	const [ totalCalls, fetchTotalCalls, resetTotalCalls] = useTotalCalls({ accountId });

	// Local Contexts.
	const { state: { dateFrom, dateTo, metricInterval}} = React.useContext(CurrentReportContext);
	
	// Component Lifecycle Methods.
	useEffect(() => {
		fetchTotalCalls({
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
			metricInterval: metricInterval,
		});
	}, [dateFrom, dateTo, metricInterval]);

	return (
		<div className='total-calls'>
			<TotalCallsGraph isLoading={totalCalls.isLoading} data={selectTotalCallsByTotal(totalCalls)} />
			<TotalCallsDataTable isLoading={totalCalls.isLoading} data={selectTotalCallsReport(totalCalls)} />
		</div>
	);
};

export default withBlock(TotalCalls, {
	header: {
		heading: 'CALLS',
		actions: [ <CurrentReportFilterForm siteFilter={{isVisible: false}} dateRangeSelector={{visibleOptions: ['lastMonth', 'lastWeek', 'lastDay', 'lastEightHours']}} /> ]
	}
});
