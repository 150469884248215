import { createSelector, OutputSelector } from 'reselect';
import { PoorCallsState } from './exports.types';

// Custom types for specialized selectors.
export type PoorCallsSegmentTotal = {
	segments: {
		access: number,
		local: number,
		nVNet: number
	},
	total: number
}

export type PoorCallsSegmentTotalWithOffender = {
	offender: {
		name: string,
		calls: number,
		percentage: number
	}
} & PoorCallsSegmentTotal

// Local state selectors.
const selectPoorCallsState = (state: PoorCallsState) => state === null ? null : state;

export const selectPoorCallsReport = createSelector(
	selectPoorCallsState,
	state => state === null ? null : state.report
);

// Mocks anticipated report data set for local testing.
const selectMockData = createSelector(
	selectPoorCallsReport,
	state => ([
		{
			'timestamp': '2019-10-20 21:28:09',
			'sipDate': '2019-10-20',
			'nVNet': {
				'in': 22,
				'out': 1
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-21 01:34:53',
			'sipDate': '2019-10-21',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 11,
				'out': 2
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-22 00:27:32',
			'sipDate': '2019-10-22',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 44,
				'out': 22
			}
		},
		{
			'timestamp': '2019-10-23 00:51:54',
			'sipDate': '2019-10-23',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 1
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-24 00:42:55',
			'sipDate': '2019-10-24',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-25 00:01:36',
			'sipDate': '2019-10-25',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-26 02:06:45',
			'sipDate': '2019-10-26',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-27 00:13:29',
			'sipDate': '2019-10-27',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-28 01:27:39',
			'sipDate': '2019-10-28',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-29 00:14:22',
			'sipDate': '2019-10-29',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-30 00:14:50',
			'sipDate': '2019-10-30',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-10-31 00:24:27',
			'sipDate': '2019-10-31',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-01 01:10:59',
			'sipDate': '2019-11-01',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-02 00:59:00',
			'sipDate': '2019-11-02',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-03 00:10:09',
			'sipDate': '2019-11-03',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-04 01:14:01',
			'sipDate': '2019-11-04',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-05 00:01:07',
			'sipDate': '2019-11-05',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 1
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-06 03:09:34',
			'sipDate': '2019-11-06',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-07 00:21:51',
			'sipDate': '2019-11-07',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-08 00:30:07',
			'sipDate': '2019-11-08',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-09 02:58:11',
			'sipDate': '2019-11-09',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-10 02:18:06',
			'sipDate': '2019-11-10',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-11 00:02:12',
			'sipDate': '2019-11-11',
			'nVNet': {
				'in': 0,
				'out': 1
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-12 00:02:52',
			'sipDate': '2019-11-12',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-13 00:33:09',
			'sipDate': '2019-11-13',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-14 00:04:35',
			'sipDate': '2019-11-14',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-15 00:04:41',
			'sipDate': '2019-11-15',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-16 00:19:28',
			'sipDate': '2019-11-16',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-17 00:55:10',
			'sipDate': '2019-11-17',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		},
		{
			'timestamp': '2019-11-18 00:04:13',
			'sipDate': '2019-11-18',
			'nVNet': {
				'in': 0,
				'out': 0
			},
			'access': {
				'in': 0,
				'out': 0
			},
			'local': {
				'in': 0,
				'out': 0
			}
		}

	])
);

// Selects modified report showing sum of poor calls per segment, per date.
export const selectPoorCallsDailySegmentTotal = createSelector(
	selectPoorCallsReport,
	state => state === null ? null : state
		.map(item => {
			const calculateSegmentSum = (obj: {[key: string]: number}) => {
				return Object.keys(obj).reduce((acc: number, entry: string) => obj[entry] ? acc + obj[entry] : acc, 0);
			};
			
			return {
				...item,
				access: calculateSegmentSum(item.access),
				local: calculateSegmentSum(item.local),
				nVNet: calculateSegmentSum(item.nVNet),
			};
		})
);

// Selects modified report showing sum of poor calls per segment, for all records.
export const selectPoorCallsSegmentTotal = createSelector(
	selectPoorCallsDailySegmentTotal,
	state => state === null ? null : state
		.reduce((acc, item) => {
			const access = item.access
				? acc.segments.access + item.access
				: acc.segments.access;

			const local = item.local
				? acc.segments.local + item.local
				: acc.segments.local;

			const nVNet = item.nVNet
				? acc.segments.nVNet + item.nVNet
				: acc.segments.nVNet;

			return {
				segments: {
					access,
					local,
					nVNet,
				},
				total: access + local + nVNet,
			};
		}, {
			segments: {
				access: 0,
				local: 0,
				nVNet: 0,
			},
			total: 0,
		})
);

// Selects modified report showing sum of poor calls per segment, with primary offender.
export const selectPoorCallsSegmentTotalWithOffender = createSelector(
	selectPoorCallsSegmentTotal,
	state => {
		if (state === null) { return null; }

		const { segments, total } = state;

		const identifyOffendingSegments = (obj: {[key: string]: number}): string[] => {
			const keys = Object.keys(obj);
			const offendingValue = keys
				.map(k => obj[k])
				.reduce((acc, entry) => entry >= acc ? entry : acc, 0);

			return keys.filter(k => obj[k] === offendingValue);
		};
	
		// Finds the primary offender, using the first attribute (alpha sort) when multiple matches.
		const [primaryOffender] = identifyOffendingSegments(segments).sort();

		return {
			...state,
			offender: {
				name: primaryOffender,
				// @ts-ignore
				calls: segments[primaryOffender],
				// @ts-ignore	
				percentage: segments[primaryOffender] / total,
			}
		};
	}
);