import React, { useContext } from 'react';
import moment from 'moment';
import { createSelector } from 'reselect';

// Import local shared Components.
import { withBlock } from 'src/components/shared/Block';
import { CurrentReportContext } from 'src/components/shared/context/CurrentReport';
import { usePoorCalls } from 'src/components/shared/hooks/usePoorCalls';
import { selectPoorCallsSegmentTotalWithOffender } from 'src/components/shared/hooks/usePoorCalls/exports.selectors';
import { useCallDrift } from 'src/components/shared/hooks/useCallDrift';
import { selectCallDriftReport } from 'src/components/shared/hooks/useCallDrift/exports.selectors';

// Import local Components.
import QualityOverviewSnapshot from './QualityOverviewSnapshot';

export interface QualityOverviewContainerProps {
	accountId: string | number
}
 
const QualityOverviewContainer: React.FC<QualityOverviewContainerProps> = ({ accountId }) => {
	// Local state definitions.
	const [poorCalls, fetchPoorCalls] = usePoorCalls({accountId});
	const [callDrift, fetchCallDrift] = useCallDrift({accountId});

	// Local context definitions.
	const {state: { dateTo, dateFrom }} = useContext(CurrentReportContext);
  
	// Selects the attribute of the 30 day poor call average from the latest record.
	const selectCallDriftTotalAverage = createSelector(
		selectCallDriftReport,
		state => state == null ? null : state
			.map(item => item.poorCalls30DayAverageAgg
				? item.poorCalls30DayAverageAgg.poorCallAverage
				: null
			)
			.reduce((prev, cur) => prev == null ? cur : prev, null)
	);

	// Selects the attribute of the poor call offender's name.
	const selectPoorCallsOffenderName = createSelector(
		selectPoorCallsSegmentTotalWithOffender,
		state => state == null ? null : state.offender.name
	);

	// Selects the attribute of the poor call offender's percentage.
	const selectPoorCallsOffenderPercentage = createSelector(
		selectPoorCallsSegmentTotalWithOffender,
		state => state == null ? null : state.offender.percentage
	);

	// Component lifecycle effects.
	React.useEffect(() => {
		const fetchData = async () => {
			await fetchPoorCalls({
				dateFrom: moment(dateFrom).toISOString(),
				dateTo: moment(dateTo).toISOString()
			});

			await fetchCallDrift({
				dateFrom: moment(dateTo).subtract(1, 'day').toISOString(),
				dateTo: moment(dateTo).toISOString(),
			});
		};

		fetchData();
	}, [dateFrom, dateTo]);

	return (
		<QualityOverviewSnapshot
			isLoading={callDrift.isLoading || poorCalls.isLoading ? true : false}
			total={ selectCallDriftTotalAverage(callDrift) || 0 }
			source={ selectPoorCallsOffenderName(poorCalls) || 'N/A' }
			sourcePercentage={ selectPoorCallsOffenderPercentage(poorCalls) || 0}
		/>
	);
};

export default withBlock(QualityOverviewContainer, { heading: 'Quality'});
