import * as React from 'react';
import moment from 'moment';

// Import local Components.
import { useCallConcurrency } from 'src/components/shared/hooks/useCallConcurrency';
import { CurrentReportContext } from 'src/components/shared/context/CurrentReport';
import { selectConcurrentCalls } from 'src/components/shared/hooks/useCallConcurrency/exports.selectors';
import ConcurrencyGraph from './ConcurrencyGraph';
import ConcurrencyDataTable from './ConcurrencyDataTable';

const ConcurrencyContainer: React.SFC<{accountId: string|number}> = ({accountId}) => {

	// Local custom Hooks.
	const [concurrentCalls, fetchConcurrentCalls, resetConcurrentCalls ] = useCallConcurrency({accountId: accountId});

	// Local Contexts.
	const { state: { dateFrom, dateTo, metricInterval }} = React.useContext(CurrentReportContext);

	// Component lifecycle effects.
	React.useEffect(() => {
		fetchConcurrentCalls({
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
			metricInterval,
		});
	}, [dateFrom, dateTo, metricInterval]);

	return (
		<div className="concurrent-calls">
			<ConcurrencyGraph reports={selectConcurrentCalls(concurrentCalls)} />
			<ConcurrencyDataTable isLoading={concurrentCalls.isLoading} reports={selectConcurrentCalls(concurrentCalls)} />
		</div>
	);
};
 
export default ConcurrencyContainer;