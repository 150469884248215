import * as React from 'react';
import moment from 'moment';

// Import local Components.
import { DataTable } from 'src/components/shared/data-table';

export type CallLatencyReport = {
  timestamp: Date,
  delay: number
}

export type ConcurrencyDataTableProps = {
  isLoading: boolean,
  reports: CallLatencyReport[] | null
}

const ConcurrencyDataTable: React.FC<ConcurrencyDataTableProps> = ({isLoading, reports}) => {
	return (
		<DataTable
			className="data-table--status-reports"
			data={[reports ? reports : []]}
			columns={[
				{
					Header: 'Date',
					id: 'sipDate',
					accessor: d => d.sipDate,
				},
				{
					Header: 'Delay',
					id: 'delay',
					accessor: d => d.delay
				},
			]}
			pageSize={reports && reports.length > 5 ? 15 : 5}
			filterable={false}
			loading={isLoading}
		/>
	);
};
 
export default ConcurrencyDataTable;