import React from 'react';
import { Column, TableCellRenderer } from 'react-table';
import moment from 'moment';
import { DataTable } from 'src/components/shared/data-table';
import { CallStatisticsReport } from 'src/components/shared/hooks/useCallStatistics/exports.types';

import 'react-table/react-table.css';
import './styles.scss';

export interface CallQOSStatisticsDataTableProps {
	isLoading: boolean,
  data: CallStatisticsReport[] | null
}

const CallQOSStatisticsDataTable: React.FC<
  CallQOSStatisticsDataTableProps
> = ({ isLoading, data }) => {

	// Custom cell renderer for the timestamp attribute.
	const CellTimestamp: TableCellRenderer = ({value}) => {
		const date = moment(value);
		return (
      <>
        <span className="datetime__date">{date.format('YYYY/MM/DD')}</span>
        <span className="datetime__time" style={{marginLeft: '1ch', opacity: .5}}>{date.format('HH:mm:ss')}</span>
      </>
		);
	};

	const columns: Column<CallStatisticsReport>[] = [
		{
			Header: 'Date',
			id: 'date',
			accessor: d => d.timestamp,
			Cell: CellTimestamp,
		},
		{
			Header: 'Good',
			id: 'good',
			accessor: d => d.good,
		},
		{
			Header: 'Marginal',
			id: 'marginal',
			accessor: d => d.marginal
		},
		{
			Header: 'Poor',
			id: 'poor',
			accessor: d => d.poor
		}
	];
	
	return (
		<DataTable
			className="data-table--call-quality-statistics"
			columns={columns}
			data={data || []}
			defaultPageSize={30}
			loading={isLoading}
		/>
	);
};

export default CallQOSStatisticsDataTable;
