import config from './config';

import { WebApplication } from '@nexvortex/framework';
import ReactExt from '@nexvortex/extension-react';
import ReactDomExt from '@nexvortex/extension-react-dom';
import ReactRouterDomExt from '@nexvortex/extension-react-router-dom';
import OApiExt from '@nexvortex/extension-oapi';
import ReactOApiExt from '@nexvortex/extension-react-oapi';

import * as React from 'react';

import './index.scss';
import App from './App';

new WebApplication({
	container: document.getElementById('root'),
	extensions: [
		new OApiExt({
			baseUrl: config.oapiBaseUrl,
			clientId: config.oapiClientId,
			clientSecret: config.oapiClientSecret
		}),
		new ReactExt(<App/>),
		new ReactOApiExt(),
		new ReactDomExt(),
		new ReactRouterDomExt()
	]
});
