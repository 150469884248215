
const csv = '.csv';

export const poorCallsByDateHeadersCSV: {label: string; key: string}[] = [
	{ label: 'DATE', key: 'timestamp' },
	{ label: 'NVIN', key: 'nvin' },
	{ label: 'NVOUT', key: 'nvout' },
	{ label: 'ACIN', key: 'acin' },
	{ label: 'ACOUT', key: 'acout' },
	{ label: 'LOCIN', key: 'locin' },
	{ label: 'LOCOUT', key: 'locout' }
];

export const poorCallsByDateFilenameCSV: string | undefined = 'Poor_Calls_By_Date' + csv;

export const poorCallsByDayOfWeekHeadersCSV: {label: string; key: string}[] = [
	{ label: 'DAY', key: 'timestamp' },
	{ label: 'NVIN', key: 'nvin' },
	{ label: 'NVOUT', key: 'nvout' },
	{ label: 'ACIN', key: 'acin' },
	{ label: 'ACOUT', key: 'acout' },
	{ label: 'LOCIN', key: 'locin' },
	{ label: 'LOCOUT', key: 'locout' }
];

export const poorCallsByDayOfWeekFilenameCSV: string | undefined = 'Poor_Calls_By_Day_Of_Week' + csv;

export const poorAndMarginalCallsHeadersCSV: {label: string; key: string}[] = [
	{ label: 'DAY', key: 'timestamp' },
	{ label: 'DIR', key: 'dir' },
	{ label: 'TAG', key: 'tag' },
	{ label: 'MOS NVIN', key: 'mos/nvin' },
	{ label: 'MOS NVOUT', key: 'mos/nvout' },
	{ label: 'MOS ACIN', key: 'mos/acin' },
	{ label: 'MOS ACOUT', key: 'mos/acout' },
	{ label: 'MOS LOCIN', key: 'mos/locin' },
	{ label: 'MOS LOCOUT', key: 'mos/locout' },
	{ label: 'LOSS NVIN', key: 'loss/nvin' },
	{ label: 'LOSS NVOUT', key: 'loss/nvout' },
	{ label: 'LOSS ACIN', key: 'loss/acin' },
	{ label: 'LOSS ACOUT', key: 'loss/acout' },
	{ label: 'LOSS LOCIN', key: 'loss/locin' },
	{ label: 'LOSS LOCOUT', key: 'loss/locout' },
	{ label: 'SIP DATE', key: 'sipdate' },
];

export const poorAndMarginalCallsFilenameCSV: string | undefined = 'Poor_And_Marginal_Calls' + csv;
