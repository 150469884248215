import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import moment from 'moment';

import { Block } from 'src/components/shared/Block';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';
import { usePoorCalls } from 'src/components/shared/hooks/usePoorCalls';
import PoorCallsByTimeOfDayGraph from './PoorCallsByTimeOfDayGraph';
import PoorCallsByTimeOfDayDataTable from './PoorCallsByTimeOfDayDataTable';
import { selectPoorCallsReport } from 'src/components/shared/hooks/usePoorCalls/exports.selectors';
import { withExportHandler, ExportHOCProps } from 'src/components/shared/ExportHandler';

export interface PoorCallsByTimeOfDayProps {
	accountId: string|number
}

const PoorCallsByTimeOfDay: React.FC<PoorCallsByTimeOfDayProps & ExportHOCProps> = ({ accountId, reactTableRef = useRef(null) }) => {

	// Local custom Hooks.
	const [poorCalls, fetchPoorCalls, resetPoorCalls] = usePoorCalls({accountId});

	// Local contexts.
	const {state: {dateFrom, dateTo, metricInterval}} = React.useContext(CurrentReportContext);

	useEffect(() => {
		const fetchData = async () => {
			fetchPoorCalls({
				dateFrom: moment(dateFrom).toISOString(),
				dateTo: moment(dateTo).toISOString(),
				metricInterval: metricInterval,
				reportType: 'hour',
			});
		};

		fetchData();
	}, [dateFrom, dateTo, metricInterval]);

	return (
		<Block
			header={{
				render: () =>
					<Container fluid className="p-0">
						<Row>
							<Col lg='12'>
								<Nav className="d-flex justify-content-end">
									<NavItem className="mr-3">
										<CurrentReportFilterForm siteFilter={{isVisible: false}} dateRangeSelector={{visibleOptions: ['lastMonth', 'lastWeek', 'lastDay']}} />
									</NavItem>
								</Nav>
							</Col>
						</Row>
					</Container>
			}}
		>
			<div className='poor-calls-by-time-of-day'>
				<PoorCallsByTimeOfDayGraph isLoading={poorCalls.isLoading} report={selectPoorCallsReport(poorCalls) || []} />
				<PoorCallsByTimeOfDayDataTable
					isLoading={poorCalls.isLoading}
					report={selectPoorCallsReport(poorCalls) || []}
					reactTableRef={reactTableRef}
				/>
			</div>
		</Block>
	);
};

export default withExportHandler(PoorCallsByTimeOfDay);
