import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { match } from 'react-router';

// Import shared local Components.
import { PageComponentProps } from '@nexvortex/page';

// Import local Components.
import { default as QualityOverview } from 'src/components/features/Blocks/QualityOverview';
import AvailabilityOverview from 'src/components/features/Blocks/AvailabilityOverview/AvailabilityOverview';
import { VisibilityOverview } from '../../Blocks/VisibilityOverview';

export interface DashboardPageProps {}

const DashboardPage: React.SFC<DashboardPageProps & PageComponentProps> = ({ match }) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container
			fluid
			className="page page--msip-dashboard"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>Dashboard</h2>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<QualityOverview accountId={accountId} />
				</Col>
				<Col lg={6}>
					<AvailabilityOverview />
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<VisibilityOverview accountId={accountId} />
				</Col>
			</Row>
		</Container>
	); 
};

export default DashboardPage;