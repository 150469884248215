import React from 'react';
import moment from 'moment';
import { LinearScale } from '@nivo/scales';
import { ResponsiveLine } from '@nivo/line';

// Import local Components.
import { CallDriftReport } from 'src/components/shared/hooks/useCallDrift/exports.types';
import { GraphResultsMissingAlert, GraphResultsLoadingAlert } from 'src/components/shared/Alerts';

// Import local styles.
import './styles.scss';

export interface CallDriftGraphProps {
	isLoading: boolean,
  data: CallDriftReport[]
}

const CallDriftGraph: React.FC<
  CallDriftGraphProps
> = ({ isLoading, data: reports }) => {

	// Sorts array of reports by timestamp.
	const sortByTimestamp = (a: CallDriftReport, b: CallDriftReport) => {
		if (a.sipDate === b.sipDate) {
			return 0;
		}
		return moment.utc(a.sipDate).diff(moment.utc(b.sipDate));
	};
	
	// Transforms reports for linear graph format.
	const prepareLineChartData = (reports: CallDriftReport[]) => {
		let formatted = reports.sort((a, b) => sortByTimestamp(a, b))
			.filter(item => item.poorCalls30DayAverageAgg)
			.map(item => {
				return {
					x: moment.utc(item.sipDate).format('YYYY-MM-DD HH:mm:ss'),
					y: item.poorCalls30DayAverageAgg.poorCallAverage.toFixed(2),
					datum: item,
				};
			});
		return [{
			id: 'averagePoorCalls',
			data: formatted
		}];
	};
	
	// Calculates a fixed Y-Axis graph minimum value.
	const calculateYAxisMin = (reports: CallDriftReport[]): LinearScale['min'] => {
		const aggregatedAverageValues = reports
			.filter(item => item.poorCalls30DayAverageAgg)
			.map(item => item.poorCalls30DayAverageAgg.poorCallAverage);
		const value = Math.min(...aggregatedAverageValues);
		return value - (value / 2);
	};
	
	// Calculates a fixed Y-Axix graph maximum value.
	const calculateYAxisMax = (reports: CallDriftReport[]): LinearScale['max'] => {
		const aggregatedAverageValues = reports
			.filter(item => item.poorCalls30DayAverageAgg)
			.map(item => item.poorCalls30DayAverageAgg.poorCallAverage);
		const value = Math.max(...aggregatedAverageValues);
		return value + (value / 2);
	};
	
	// Defines shared graph properties for all graph types.
	const commonProperties = {
		margin: { top: 50, right: 20, bottom: 50, left: 70 },
		animate: true,
	};

	if (isLoading) {
		return <GraphResultsLoadingAlert />;
	}

	if (reports && !reports.length) {
		return <GraphResultsMissingAlert />;
	}
	
	return (
		<div className='graph graph--call-drift'>
			<ResponsiveLine
				{...commonProperties}
				data={prepareLineChartData(reports)}
				xScale={{
					type: 'time',
					format: '%Y-%m-%d %H:%M:%S', // Uses D3.js timeformat
					precision: 'second',
				}}
				xFormat="time:%Y-%m-%d"
				yScale={{
					type: 'linear',
					stacked: false,
					min: calculateYAxisMin(reports),
					max: calculateYAxisMax(reports),
				}}
				axisLeft={{
					legend: 'Poor Call Average (%)',
					legendOffset: -60,
					legendPosition: 'middle',
				}}
				axisBottom={{
					legend: 'Date/Time (UTC)',
					legendPosition: 'middle',
					legendOffset: 40,
					format: '%b %d',
				}}
				curve="linear"
				colors="rgb(102,90,127)"
				lineWidth={2}
				pointSize={8}
				pointBorderWidth={1}
				pointBorderColor={{
					from: 'color',
					modifiers: [['darker', 0.3]],
				}}
				useMesh={true}
				enableGridX={false}
				enableSlices="x"
				sliceTooltip={(data: any) => {
					const { slice: { points: [ point ] } } = data;
					return (
						<div style={{
							padding: 10,
							opacity: .95,
							color: '#fff',
							background: point.color ? point.color : '#333',
						}}>
							<div className="d-flex justify-content-between">
								<span style={{ fontWeight: 700, textTransform: 'capitalize', marginRight: 10}}>Date:</span>
								<span>{moment.utc(point.data.x).format('YYYY-MM-DD')}</span>
							</div>
							<div className="d-flex justify-content-between">
								<span style={{ fontWeight: 700, textTransform: 'capitalize', marginRight: 10}}>Total Calls:</span>
								<span>{`${point.data.datum.totalCallsCount}`}</span>
							</div>
							<div className="d-flex justify-content-between">
								<span style={{ fontWeight: 700, textTransform: 'capitalize', marginRight: 10}}>Poor Calls:</span>
								<span>{`${point.data.datum.poorCallsCount}`}</span>
							</div>
							<div className="d-flex justify-content-between">
								<span style={{ fontWeight: 700, textTransform: 'capitalize', marginRight: 10}}>30 Day Average:</span>
								<span>{`${point.data.yFormatted}%`}</span>
							</div>
						</div>
					);
				}}
			/>
		</div>
	);
};

export default CallDriftGraph;
