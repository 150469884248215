import React from 'react';
import { PartialObject } from 'lodash';
import { Column, TableCellRenderer } from 'react-table';
import moment from 'moment';
import { DataTable } from 'src/components/shared/data-table';
import { TotalCallsReport } from 'src/components/shared/hooks/useTotalCalls/exports.types';

import 'react-table/react-table.css';
import './styles.scss';

export interface TotalCallsDataTableProps {
	isLoading: boolean,
  data: TotalCallsReport[] | null
}

// Custom cell renderer for the timestamp field.
const CellTimestamp: TableCellRenderer = ({value}) => {
	const date = moment(value);
	return (
		<>
			<span className="datetime__date">{date.format('YYYY/MM/DD')}</span>
			<span className="datetime__time" style={{marginLeft: '1ch', opacity: .5}}>{date.format('HH:mm:ss')}</span>
		</>
	);
};

const TotalCallsDataTable: React.FC<
  TotalCallsDataTableProps
> = ({ isLoading, data }) => {

	const columns: Column<TotalCallsReport>[] = [
		{
			Header: 'Date',
			id: 'groupDate',
			accessor: d => d.groupDate,
			Cell: CellTimestamp,
		},
		{
			Header: 'Inbound',
			id: 'inboundCalls',
			accessor: d => d.calls.inbound ? d.calls.inbound : '(Currently Unavailable)' 
		},
		{
			Header: 'Outbound',
			id: 'outboundCalls',
			accessor: d => d.calls.outbound ? d.calls.outbound : '(Currently Unavailable)' 
		},
		{
			Header: 'Total Calls',
			id: 'totalCalls',
			accessor: d => d.calls.total,
		},
	];
	
	return (
		<DataTable
			className="data-table--total-calls"
			columns={columns}
			data={data || []}
			defaultPageSize={30}
			loading={isLoading}
		/>
	);
};

export default TotalCallsDataTable;
