import React from 'react';
const NSVMIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 466.1 432.3">
		<g transform="translate(-9.141 -27.07)">
			<g transform="translate(9.141 27.07)">
				<path d="M430.3,0.3H37.5C11,0.3,0,11.3,0,37.6c0,92.4,0,184.7,0,277c0,26.2,10.7,36.9,36.5,36.9
        c49.2,0,98.5,0,147.7,0c-0.1,2.8-0.4,5.5-0.6,8.3c-0.6,8.2-0.9,16.4-2,24.4c-0.3,2.3-0.9,4.6-1.7,6.7c-2.4,7.2-7.4,13.4-13.9,17.3
        l-7.6,4.7c-4.4,2.7-8.9,5.3-13.3,8.1c-2,1.2-3.7,2.9-4.8,5c-1.5,3.2,0,5.6,3.6,6.2c1.2,0.1,2.4,0.1,3.6,0c57.4,0,114.7,0,172,0
        c3.1,0,7.1,1.1,8-3.3c0.8-4-2.1-6.2-5.1-8l-14-8.4c-1.7-1.1-3.5-2.1-5.2-3.2c-6.8-3.8-12-9.7-14.9-16.9c-1.4-3.6-2.3-7.4-2.5-11.3
        c-0.6-9.7-1.5-19.5-2.3-29.6h146.8c24.3,0,35.5-11.3,35.5-35.9V35.4c2.3-17.1-9.7-32.8-26.9-35.1C436-0.1,433.1-0.1,430.3,0.3z
        M250.2,325c-5.1,9.6-17,13.2-26.6,8.1c-3.5-1.8-6.3-4.7-8.1-8.1c-1.7-3.2-2.6-6.7-2.6-10.4c0-11,8.9-20,19.9-20s20,8.9,20,19.9
        c0,0.4,0,0.8,0,1.1C252.7,319,251.8,322.2,250.2,325z M427.5,277.2c-129.4-0.2-258.8-0.3-388.2-0.1c-13.4,0-13.8-0.5-13.8-14.1V42
        c0-15.7,0.1-15.8,16.4-15.8c46.8,0,304.1,0,368.1,0h16c13.7,0,14.3,0.6,14.3,13.7c0,74.7,0,149.4,0.2,224.2
        C440.5,273.5,437.7,277.2,427.5,277.2L427.5,277.2z"/>
				<path d="M154.6,172.3c-1.8,0-2.3,0-2.4,0.2c-10.5,17.3-27.2,16.8-44.3,15.7c-7-0.4-14.1,0-21.7,0v-20.5
        c9.2,0,18.2-0.6,27.1,0.2c9.1,1.7,18.4-2.5,23-10.5c2.3-3.1,4.9-6,7.7-8.7c10-11.4,15.7-10.7,23.3,2.7c4.6,8,9,16.2,14.8,26.7
        c2.7-7.9,4.7-13.2,6.3-18.5c9.2-30.3,18.4-60.5,27.5-90.8c1.8-6,3.6-12.1,11.8-11.8s9.6,6.2,11.1,12.4
        c8.7,36.1,17.5,72.1,26.4,108.1c0.6,3.1,2.2,6,4.5,8.2c3.4-12.2,6.9-24.3,10.2-36.6c3.5-13.1,6.3-26.4,10.5-39.3
        c1.7-4.6,5.3-8.2,9.8-10.2c6-2.1,9.7,2.6,12.3,8.2c8,17.7,16.5,35.1,24.4,52.8c1.5,4.7,6.1,7.7,11,7.1c10.3-0.4,20.6,0,31.5,0
        v20.4c-16.2,0-32.3,0.4-48.4-0.4c-2.9-0.1-6.6-4.8-8.4-8.2c-4.9-9.1-8.9-18.7-13.4-28c-1.2-2.5-2.7-4.8-5.4-9.6
        c-5.4,20-10.2,37.6-15,55.1c-3.3,12.1-6.7,24.3-9.7,36.5c-1.5,6.2-4,11.1-11.3,11s-9.6-5.4-11.1-11.4
        c-9-37.3-18.1-74.6-27.1-111.9c-0.7-3.3-2.3-6.3-4.7-8.7c-4.6,15-9.3,30-13.9,45.1c-4.9,16.1-9.2,32.3-14.8,48.1
        c-1.8,4.9-5.6,8.8-10.5,10.6c-3.2,0.6-8.7-4.5-11.3-8.4C167.3,196.3,161.2,184.2,154.6,172.3z"/>
			</g>
		</g>
	</svg>

);

export default NSVMIcon;
