import * as React from 'react';
import moment from 'moment';

// Import local Components.
import { DataTable } from 'src/components/shared/data-table';
import { CallConcurrencyReportTransform } from 'src/components/shared/hooks/useCallConcurrency/exports.types';

export type ConcurrencyDataTableProps = {
  isLoading: boolean,
  reports: CallConcurrencyReportTransform[] | null
}

const ConcurrencyDataTable: React.FC<ConcurrencyDataTableProps> = ({isLoading, reports}) => {
	return (
		<DataTable
			className="data-table--status-reports"
			data={reports ? reports : []}
			columns={[
				{
					Header: 'Date',
					id: 'sipDate',
					accessor: d => d.sipDate,
				},
				{
					Header: 'Peak Time',
					id: 'timestamp',
					accessor: d => d.timestamp,
					Cell: ({value}) => moment(value).format('HH:mm:ss'),
				},
				{
					Header: 'Calls',
					id: 'calls',
					accessor: d => d.calls
				},
			]}
			pageSize={reports && reports.length > 5 ? 15 : 5}
			filterable={false}
			loading={isLoading}
		/>
	);
};
 
export default ConcurrencyDataTable;