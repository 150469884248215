import React, { useState } from 'react';
import moment from 'moment';
import parse from 'parse-color';
import { BarSvgProps, BarItemProps } from '@nivo/bar';
import VisibilityBarChartGraph from './VisibilityBarChartGraph';

export type VisibilityBarChartProps = {
	isLoading?: boolean,
	dateFrom: Date,
	dateTo: Date,
  graphTitle: string,
  graphTitleColor?: string
} & Partial<BarSvgProps>;

const ExampleBarComponent: React.StatelessComponent<BarItemProps> = ({
	color, data, onClick, tooltip, showTooltip, hideTooltip, ...rest
}) => {
	// console.log('onClick', onClick);

	// Local state.
	const [rectColor, setRectColor] = useState(color);

	// Manipulates a color string's alpha channel to a given value.
	const adjustColorAlpha = (color: string, value: number = 1) => {
		const {rgba} = parse(color);

		if (rgba !== undefined) {
			const [r,g,b] = rgba;
			return `rgba(${r}, ${g}, ${b}, ${value})`;
		}
		
		console.warn('Unable to convert color to rgba value. Returning default color.');
		return color;
	};

	const mouseOver = (event: React.MouseEvent<HTMLCanvasElement>) => {
		setRectColor(adjustColorAlpha(color, .75));
		// showTooltip(tooltip, event);
	};

	return (
		<rect
			fill={rectColor}
			rx={5}
			onMouseOver={(event: any) => mouseOver(event)}
			onMouseOut={() => setRectColor(color)}
			{...rest}
		/>
	);
};

const VisibilityBarChart: React.FC<VisibilityBarChartProps> = ({ isLoading, data, dateFrom, dateTo, graphTitle, graphTitleColor, ...rest }) => {
  
	// Defines shared graph properies.
	const commonProperties: Partial<BarSvgProps> = {	
		animate: true,
		axisBottom: {
			format: d => moment(d).format('MM/DD'),
			tickSize: 5,
		},
		axisLeft: {
			tickSize: 0,
			tickValues: 4,
		},
		// barComponent: ExampleBarComponent,
		enableLabel: false,
		enableGridY: false,
		indexBy: 'timestamp',
		labelTextColor: 'rgb(255,255,255)',
		margin: { 
			top: 20,
			right: -20,
			bottom: 80,
			left: 30
		},
		maxValue: 'auto',
		minValue: 0,
		motionStiffness: 90,
		motionDamping: 15,
		padding: 0.4,
		theme: {
			tooltip: {
				container: {
					borderRadius: 10,
					boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.16)',
				}
			}
		}
	};
  
	const startDate = moment(dateFrom).format('MM/DD/YYYY');
	const endDate = moment(dateTo).format('MM/DD/YYYY');
	
	return (
		<div className="graph">
			<h5 className="graph__title" style={{color: graphTitleColor || '#333'}}>
				<span>{graphTitle}</span>
				<span style={{color: '#97999B', marginLeft: '2ch', fontSize: '.85em', fontWeight: 500}}>{`${startDate} - ${endDate}`}</span>
			</h5>
			<VisibilityBarChartGraph isLoading={isLoading} data={data} {...rest} />
		</div>
	);
};
 
export default VisibilityBarChart;