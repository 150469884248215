import * as React from 'react';
import { match } from 'react-router';
import { PageComponentProps } from '@nexvortex/page';
import { Row, Container, Col } from 'reactstrap';
import { CallDrift } from '../../Blocks/CallDrift';
import QoSReportsNavigationToggle from '../../Blocks/QoSReportsNavigationToggle';

export interface CallDriftPageProps {}
 
const CallDriftPage: React.FC<CallDriftPageProps & PageComponentProps> = ({match}) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;
	
	return (
		<Container
			fluid
			className="page page--msip-call-drift"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>QOS Drift Report</h2>
					<QoSReportsNavigationToggle currentPageTitle='QoS Drift' />
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<CallDrift accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default CallDriftPage;