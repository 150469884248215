import React, { useEffect, useState } from 'react';
import moment from 'moment';

// Import shared components.
import { withBlock } from 'src/components/shared/Block';
import { useCallAverageBySegment } from 'src/components/shared/hooks/useCallAverageBySegment';
import { CallAverageBySegmentArgs } from 'src/components/shared/hooks/useCallAverageBySegment/exports.types';
import { selectCallAverageReport } from 'src/components/shared/hooks/useCallAverageBySegment/exports.selectors';
import AverageBySegmentTable from './AverageBySegmentTable';
import AverageBySegmentGraph from './AverageBySegmentGraph';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';

export interface AverageBySegmentProps {
	accountId: number|string
}

const AverageBySegment: React.SFC<AverageBySegmentProps> = ({ accountId }) => {
	// Local hooks.
	const [callAverages, fetchCallAverages, resetCallAverages] = useCallAverageBySegment({accountId});

	// Local Contexts.
	const {state: { dateTo, dateFrom, metricInterval }} = React.useContext(CurrentReportContext);

	// Fetches call average by segment for an Account Site for a given period.
	const fetchCallAverageBySegment = async (dateFrom: Date, dateTo: Date, metricInterval: number = 86400) => {
		const params: CallAverageBySegmentArgs = {
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
			metricInterval: metricInterval
		};

		fetchCallAverages(params);
	};

	// Component lifecycle effects.
	useEffect(() => {
		fetchCallAverageBySegment(dateFrom, dateTo, metricInterval);
	}, [dateFrom, dateTo, metricInterval]);

	return (
		<>
      <AverageBySegmentGraph isLoading={callAverages.isLoading} data={selectCallAverageReport(callAverages)} />
      <AverageBySegmentTable isLoading={callAverages.isLoading} data={selectCallAverageReport(callAverages)} />
    </>
	);
};

export default withBlock(AverageBySegment, {
	header: {
		heading: 'Average MOS For Each Call Segment',
		actions: [ <CurrentReportFilterForm siteFilter={{isVisible: false}} dateRangeSelector={{visibleOptions: ['lastMonth', 'lastWeek', 'lastDay']}} /> ]
	}
});
