import { createSelector } from 'reselect';
import { SEERIncidentsState } from './exports.types';

// Local state selectors.
const selectSEERIncidentsState = (state: SEERIncidentsState) => state === null ? null : state;

export const selectSEERIncidentsReport = createSelector(
	selectSEERIncidentsState,
	state => state === null ? null : state.report
);

// Mocks anticipated report data set for local testing.
const selectMockData = createSelector(
	selectSEERIncidentsReport,
	state => ([
		{
			'outageId': 416,
			'createDateTime': '2018-10-26 14:40:58',
			'updateDateTime': '2018-10-26 14:40:58',
			'flagError': null,
			'sipDate': '2018-10-26',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': 148,
			'statementId': 615132,
			'incident': {
				'incidentId': 482,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 3,
			'totalOutage': 2820,
			'outageReason': {
				'outageReasonId': 3,
				'name': 'Provider or LEC Outage (non-nV)'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '204523',
			'start': '2018-10-26 07:26:30',
			'end': '2018-10-26 08:13:30'
		},
		{
			'outageId': 454,
			'createDateTime': '2018-11-04 20:56:23',
			'updateDateTime': '2018-11-04 20:56:23',
			'flagError': null,
			'sipDate': '2018-11-02',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': 148,
			'statementId': 623956,
			'incident': {
				'incidentId': 523,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 3,
			'totalOutage': 36,
			'outageReason': {
				'outageReasonId': 3,
				'name': 'Provider or LEC Outage (non-nV)'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '205403',
			'start': '2018-11-02 07:36:39',
			'end': '2018-11-02 07:37:15'
		},
		{
			'outageId': 468,
			'createDateTime': '2018-11-08 17:25:21',
			'updateDateTime': '2018-11-08 17:25:21',
			'flagError': null,
			'sipDate': '2018-11-08',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': 148,
			'statementId': 623956,
			'incident': {
				'incidentId': 537,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 3,
			'totalOutage': 9180,
			'outageReason': {
				'outageReasonId': 4,
				'name': 'Provider or LEC Maintenance (non-nV)'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '206096',
			'start': '2018-11-08 08:56:38',
			'end': '2018-11-08 11:29:38'
		},
		{
			'outageId': 577,
			'createDateTime': '2018-12-13 17:33:06',
			'updateDateTime': '2018-12-13 17:33:06',
			'flagError': null,
			'sipDate': '2018-12-12',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': 148,
			'statementId': 633055,
			'incident': {
				'incidentId': 651,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 3,
			'totalOutage': 720,
			'outageReason': {
				'outageReasonId': 3,
				'name': 'Provider or LEC Outage (non-nV)'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '209896',
			'start': '2018-12-12 21:04:24',
			'end': '2018-12-12 21:16:24'
		},
		{
			'outageId': 585,
			'createDateTime': '2018-12-17 16:22:53',
			'updateDateTime': '2018-12-17 16:22:53',
			'flagError': null,
			'sipDate': '2018-12-14',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': 148,
			'statementId': 633055,
			'incident': {
				'incidentId': 658,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 3,
			'totalOutage': 1740,
			'outageReason': {
				'outageReasonId': 3,
				'name': 'Provider or LEC Outage (non-nV)'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '210102',
			'start': '2018-12-14 08:28:19',
			'end': '2018-12-14 08:57:19'
		},
		{
			'outageId': 586,
			'createDateTime': '2018-12-17 16:25:05',
			'updateDateTime': '2018-12-17 16:25:05',
			'flagError': null,
			'sipDate': '2018-12-17',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': -1,
			'statementId': 633055,
			'incident': {
				'incidentId': 659,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 1,
			'totalOutage': 210235,
			'outageReason': {
				'outageReasonId': 3,
				'name': 'Provider or LEC Outage (non-nV)'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '210235',
			'start': '2018-12-17 08:57:19',
			'end': '2018-12-17 09:27:19'
		},
		{
			'outageId': 788,
			'createDateTime': '2019-03-02 23:08:05',
			'updateDateTime': '2019-03-02 23:08:05',
			'flagError': null,
			'sipDate': '2019-02-27',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': 148,
			'statementId': 650576,
			'incident': {
				'incidentId': 860,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 3,
			'totalOutage': 7543,
			'outageReason': {
				'outageReasonId': 1,
				'name': 'Maintenance'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '218913',
			'start': '2019-02-27 04:53:27',
			'end': '2019-02-27 06:59:10'
		},
		{
			'outageId': 801,
			'createDateTime': '2019-03-04 18:34:20',
			'updateDateTime': '2019-03-04 18:34:20',
			'flagError': null,
			'sipDate': '2019-03-02',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': 148,
			'statementId': 659429,
			'incident': {
				'incidentId': 873,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 3,
			'totalOutage': 1440,
			'outageReason': {
				'outageReasonId': 1,
				'name': 'Maintenance'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '219400',
			'start': '2019-03-02 19:45:05',
			'end': '2019-03-02 20:09:05'
		},
		{
			'outageId': 802,
			'createDateTime': '2019-03-04 18:35:30',
			'updateDateTime': '2019-03-04 18:35:30',
			'flagError': null,
			'sipDate': '2019-03-02',
			'visibility': 'Public',
			'account': {
				'accountId': 14758,
				'company': 'Angels Baseball LP',
				'userId': '',
				'userDomain': ''
			},
			'circuitId': -1,
			'statementId': 659429,
			'incident': {
				'incidentId': 873,
				'title': 'Event:ckt (ID:639014758-148)',
				'status': 'New',
				'accountId': 14758
			},
			'status': 'Assigned',
			'slaCategory': 1,
			'totalOutage': 219400,
			'outageReason': {
				'outageReasonId': 1,
				'name': 'Maintenance'
			},
			'totalUnavailability': 0,
			'unavailabilityReason': {
				'unavailabilityReasonId': 18,
				'name': 'No nV Responsibility',
				'responsibility': 'other'
			},
			'reference': '219400',
			'start': '2019-03-02 19:45:05',
			'end': '2019-03-02 20:09:05'
		}
	])
);