import { createSelector } from 'reselect';
import { CallConcurrencyState } from './exports.types';

// Local state selectors.
const selectCallConcurrencyState = (state: CallConcurrencyState) => state === null ? null : state;

export const selectCallConcurrencyReport = createSelector(
	selectCallConcurrencyState,
	state => state === null ? null  : state.report
);

export const selectConcurrentCalls = createSelector(
	selectCallConcurrencyReport,
	state => state === null ? null  : state.map(item => {
		return {
			sipDate: item.sipDate,
			timestamp: item.maxCallsDateTime,
			calls: item.maxCalls,
		};
	})
);