import { AccountActivityData, AccountActivityState } from './exports.types';

// Defines reducer action types.
export type ActionUpdateReportData = {
  type: 'UPDATE_DATA',
  value: AccountActivityData[]
}

export type ActionLoadingReportData = {
  type: 'LOADING_DATA',
  value: boolean
}

export type ActionErrorLoadingReportData = {
  type: 'ERROR_LOADING_DATA',
  value: Error
}

export type ActionResetReportData = {
  type: 'RESET_DATA',
  value: boolean
}

export type AccountActivityAction =
  | ActionUpdateReportData
  | ActionLoadingReportData
  | ActionResetReportData
  | ActionErrorLoadingReportData

// Defines reducer functionality.
export const reducer: React.Reducer<Readonly<AccountActivityState>, AccountActivityAction> = (state, action) => { 
	switch (action.type) {
	case 'LOADING_DATA':
		return {
			...state,
			isLoading: action.value,
		};
	case 'UPDATE_DATA':
		return {
			...state,
			isLoading: false,
			data: action.value
		};
	case 'RESET_DATA':
		return {
			...state,
			isLoading: false,
			data: null
		};
	case 'ERROR_LOADING_DATA':
		return {
			...state,
			isLoading: false,
			error: action.value,
			data: null
		};
	}
};