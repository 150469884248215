import React, { useState } from 'react';
import moment from 'moment';
import parse from 'parse-color';
import { ResponsiveBar, BarSvgProps, BarItemProps } from '@nivo/bar';
import { GraphResultsMissingAlert, GraphResultsLoadingAlert } from 'src/components/shared/Alerts';

export type VisibilityBarChartGraphProps = {
	isLoading?: boolean
} & Partial<BarSvgProps>;

const VisibilityBarChartGraph: React.FC<VisibilityBarChartGraphProps> = ({ isLoading, data, ...rest }) => {
  
	// Defines shared graph properies.
	const commonProperties: Partial<BarSvgProps> = {	
		animate: true,
		axisBottom: {
			format: d => moment(d).format('MM/DD'),
			tickSize: 5,
		},
		axisLeft: {
			tickSize: 0,
			tickValues: 4,
		},
		// barComponent: ExampleBarComponent,
		enableLabel: false,
		enableGridY: false,
		indexBy: 'timestamp',
		labelTextColor: 'rgb(255,255,255)',
		margin: { 
			top: 20,
			right: -20,
			bottom: 80,
			left: 30
		},
		maxValue: 'auto',
		minValue: 0,
		motionStiffness: 90,
		motionDamping: 15,
		padding: 0.4,
		theme: {
			tooltip: {
				container: {
					borderRadius: 10,
					boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.16)',
				}
			}
		}
	};

	const graphContainerHeight = 200;

	if (isLoading) {
		return (
			<div style={{height: graphContainerHeight}}>
				<GraphResultsLoadingAlert />
			</div>
		);
	}

	if (data && !data.length) {
		return (
			<div style={{height: graphContainerHeight}}>
				<GraphResultsMissingAlert />
			</div>
		);
	}
  
	return (
		<div style={{height: graphContainerHeight}}>
			<ResponsiveBar {...commonProperties} data={data || []} {...rest} />
		</div>
	);
};
 
export default VisibilityBarChartGraph;