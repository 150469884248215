import * as React from 'react';
import { RowInfo } from 'react-table';

export const TableRowExpansion: React.SFC<RowInfo> = ({original}) => {
	const {agent, event, eventType} = original;

	const agentAttributes = Object.keys(agent);
	const eventAttributes = Object.keys(event).filter(i => i !== 'trigger');
	const eventTriggerAttributes = Object.keys(event.trigger);
	const eventTypeAttributes = Object.keys(eventType);

	return (	
		<div className="rt-tr-expansion">
			<div className="details eventType-details">
				<h5>Event Type Details</h5>
				<dl>
					{ eventTypeAttributes.map( item => {
						return (
							<React.Fragment key={item}>
								<dt>{item}:</dt>
								<dd>{eventType[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details agent-details">
				<h5>Agent Details</h5>
				<dl>
					{ agentAttributes.map( item => {
						return (
							<React.Fragment key={item}>
								<dt>{item}:</dt>
								<dd>{agent[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details event-details">
				<h5>Event Details</h5>
				<dl>
					{ eventAttributes.map( item => {
						return (
							<React.Fragment key={item}>
								<dt>{item}:</dt>
								<dd>{event[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details eventType-details">
				<h5>Event Trigger Details</h5>
				<dl>
					{ eventTriggerAttributes.map( item => {
						return (
							<React.Fragment key={item}>
								<dt>{item}:</dt>
								<dd>{event.trigger[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
		</div>
	);
};

export default TableRowExpansion;