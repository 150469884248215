import * as React from 'react';
import classNames from 'classnames';
import { Card, CardProps } from 'reactstrap';

// Import local styles.
import './Block.scss';
import CardTitle from 'reactstrap/lib/CardTitle';
import CardBody from 'reactstrap/lib/CardBody';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';

export interface BlockHeaderProps {
	heading?: string,
	actions?: React.ReactElement[],
	content?: React.ReactNode,
	render?: (props: any) => React.ReactNode
}

export interface BlockProps extends CardProps  {
	heading?: string | React.ReactElement,
	header?: BlockHeaderProps
}

export const BlockHeader: React.SFC<BlockHeaderProps> = ({heading, actions, content, render}) => {
	const offsetActionsMargin = actions ? 'mb-n3' : '';

	// Allows render prop to override entire output.
	if (render) {
		return (render as any)();
	}

	return (
		<>
			<div className={classNames('d-flex flex-wrap justify-content-between align-items-center', offsetActionsMargin)}>
				{ heading && 
					<h3 className="card-header__title mr-auto">{heading}</h3>
				}

				{ actions &&
					<Nav className="card-header__actions">
						{ actions.map((item, key) => <NavItem key={key} className="ml-auto p-1">{item}</NavItem>) }
					</Nav>
				}
			</div>
			
			{ content && 
				<div className="card-header__content">{content}</div>
			}
		</>
	);
};
 
export const Block: React.SFC<BlockProps> = ({heading, header, children, className, ...props}) => {
	return ( 
		<Card tag='article' className={classNames('block', className)} {...props}>
			{ header 
				?  <CardTitle tag='header' className="card-header">
					<BlockHeader {...header} />
					 </CardTitle>
				: heading	
					? <CardTitle tag='header' className="card-header"><h3>{heading}</h3></CardTitle>
					: null
			}
			<CardBody className="card-body">
				{ children }
			</CardBody>
		</Card>
	);
};