import * as React from 'react';
import { match } from 'react-router';
import { PageComponentProps } from '@nexvortex/page';
import { Row, Container, Col } from 'reactstrap';
import { default as AvailabilityReports } from '../../Blocks/AvailabilityReports';

export interface AvailabilityReportsPageProps {}
 
const AvailabilityReportsPage: React.FC<AvailabilityReportsPageProps & PageComponentProps> = ({match}) => {
	
	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container
			fluid
			className="page page--msip-availability-reports"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>Availability Reports</h2>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<AvailabilityReports accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default AvailabilityReportsPage;