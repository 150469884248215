import * as React from 'react';
import { Block, BlockProps } from '../Block';

export const withBlock = <
  InnerProps extends object
>(
		InnerComponent: React.ComponentType<InnerProps>,
		options?: BlockProps
	): React.FC<InnerProps> => (props) => (
		<Block {...options}>
			<InnerComponent {...props as InnerProps} />
		</Block>
	);