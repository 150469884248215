const requiredEnv = {
	'REACT_APP_OAPI_BASE_URL': process.env.REACT_APP_OAPI_BASE_URL,
	'REACT_APP_OAPI_CLIENT_ID': process.env.REACT_APP_OAPI_CLIENT_ID,
	'REACT_APP_OAPI_CLIENT_SECRET': process.env.REACT_APP_OAPI_CLIENT_SECRET,
	'REACT_APP_AUTH_PORTAL_URL': process.env.REACT_APP_AUTH_PORTAL_URL
};

const [
	oapiBaseUrl,
	oapiClientId,
	oapiClientSecret,
	authPortalUrl
]: string[] = Object.entries(requiredEnv)
	.map(([ key, value ]) => {
		if (!value) {
			throw new ReferenceError(`${key} is undefined`);
		}

		return value;
	});

export const config = {
	oapiBaseUrl,
	oapiClientId,
	oapiClientSecret,
	authPortalUrl
};

export default config;
