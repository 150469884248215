import * as React from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import { LegendMouseHandler } from '@nivo/legends';
import { OrdinalColorsInstruction } from '@nivo/colors';

// Import local Components.
import { CallAverageBySegmentReport } from 'src/components/shared/hooks/useCallAverageBySegment/exports.types';
import { GraphResultsMissingAlert, GraphResultsLoadingAlert } from 'src/components/shared/Alerts';

// Import local styles.
import './styles.scss';

export interface AverageBySegmentGraphProps {
	isLoading: boolean,
  data: CallAverageBySegmentReport[] | null
}

const AverageBySegmentGraph: React.FC<AverageBySegmentGraphProps> = ({isLoading, data}) => {
	// Custom data attribute map for graph legend usage.
	const customLegendMap: {[key: string]: { label: string; color: string}} = {
		'nvnetIn': {
			label: 'nVNET: In',
			color: '#4EA7DA'
		},
		'nvnetOut': {
			label: 'nVNET: Out',
			color: '#FF8C69'
		},
		'accessIn': {
			label: 'Access: In',
			color: '#97999B'
		},
		'accessOut': {
			label: 'Access: Out',
			color: '#FFBE65'
		},
		'localIn': {
			label: 'Local: In',
			color: '#665A7F'
		},
		'localOut': {
			label: 'Local: Out',
			color: '#429577'
		},
	};

	// Local state.
	const [keys, setKeys] = React.useState<string[]>(Object.keys(customLegendMap));

	// Parses report and flattens nested objects for graph accesibility.
	const flattenReportObjects = (data: CallAverageBySegmentReport[]) => {
		return data.map(report => {
			return {
				...report,
				nvnetIn: report.nVNet.in,
				nvnetOut: report.nVNet.out,
				accessIn: report.access.in,
				accessOut: report.access.out,
				localIn: report.local.in,
				localOut: report.local.out,
			};
		});
	};
  
	// Toggles visible graph keys by a given legend id.
	const toggleRadarLegend: LegendMouseHandler = (data, event) => {
		event.preventDefault();
		const key = data.id as string;

		if (keys.length === 1) {
			setKeys(Object.keys(customLegendMap));
			return;
		}

		setKeys([key]);
	};
  
	// Retrieves a color value from the custom legend mapping.
	const selectLegendColorFromMap: OrdinalColorsInstruction = ({key}) => {
		if (customLegendMap[key] === undefined) {
			return '#333333';
		}

		return customLegendMap[key].color;
	};

	if (isLoading) {
		return <GraphResultsLoadingAlert />;
	}

	if (data && !data.length) {
		return (
			<GraphResultsMissingAlert />
		);
	}

	return (
		<div className={`average-by-segment-graph ${isLoading ? 'isLoading' : 'loaded'}`}>
			<ResponsiveRadar
				data={data ? flattenReportObjects(data) : []}
				keys={keys}
				indexBy="timestamp"
				maxValue={5}
				margin={{ top: 80, right: 80, bottom: 80, left: 60 }}
				curve="linearClosed"
				borderWidth={2}
				borderColor={{ from: 'color' }}
				gridLevels={5}
				gridShape="circular"
				gridLabelOffset={36}
				enableDots={true}
				dotSize={10}
				dotColor='#ffffff'
				dotBorderWidth={1}
				dotBorderColor={{from: 'color'}}
				enableDotLabel={false}
				dotLabel="value"
				dotLabelYOffset={-12}
				colors={selectLegendColorFromMap}
				fillOpacity={0}
				blendMode="normal"
				animate={true}
				motionStiffness={90}
				motionDamping={15}
				isInteractive={true}
				legends={[
					{
						anchor: 'left',
						direction: 'column',
						translateX: 0,
						translateY: -40,
						itemWidth: 50,
						itemHeight: 12,
						itemsSpacing: 24,
						itemTextColor: '#999',
						symbolSize: 12,
						symbolSpacing: 12,
						symbolShape: 'circle',
						onClick: (data, event) => toggleRadarLegend(data, event),
						effects: [
							{
								on: 'hover',
								style: {
									itemTextColor: '#000'
								}
							}
						]
					}
				]}
			/>
		</div>
	);
};
 
export default AverageBySegmentGraph;