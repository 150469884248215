import * as React from 'react';
import { Block } from '../../../shared/Block';
import { QOSReportsMenu } from '../../Blocks/QOSReportsMenu';
import { PageComponentProps } from '@nexvortex/page';
import { Container, Row, Col } from 'reactstrap';

export interface QOSReportsPageProps {}

const QOSReports: React.FC<QOSReportsPageProps & PageComponentProps> = () => {
	return (
		<Container
			fluid
			className="page page--qos-reports"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>Quality of Service Reports</h2>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<Block
						className='qos-reports-menu'
					>
						<QOSReportsMenu />
					</Block>
				</Col>
			</Row>
		</Container>
	);
};

export default QOSReports;
