import React, { useEffect } from 'react';
import moment from 'moment';

import { withBlock } from 'src/components/shared/Block/withBlock';
import { useCallDrift } from 'src/components/shared/hooks/useCallDrift';
import { selectCallDriftReport } from 'src/components/shared/hooks/useCallDrift/exports.selectors';
import { CallDriftDataTable } from './CallDriftDataTable';

import './styles.scss';
import { CallDriftGraph } from './CallDriftGraph';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';

export interface CallDriftProps {
	accountId: string|number
}

const CallDrift: React.SFC<CallDriftProps> = ({accountId}) => {
	// Local Custom Hooks.
	const [ callDrift, fetchCallDrift, resetCallDrift] = useCallDrift({ accountId });

	// Local Contexts.
	const { state: {dateFrom, dateTo, metricInterval }} = React.useContext(CurrentReportContext);
	
	useEffect(() => {
		fetchCallDrift({
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
			metricInterval: metricInterval,
		});
	}, [dateFrom, dateTo, metricInterval]);

	return (
		<div className='call-drift'>
			<CallDriftGraph isLoading={callDrift.isLoading} data={selectCallDriftReport(callDrift) || []} />
			<CallDriftDataTable isLoading={callDrift.isLoading} data={selectCallDriftReport(callDrift)} />
		</div>
	);
};

export default withBlock(CallDrift, {
	header: {
		heading: 'Percent of Poor Calls Over 30 Days',
		actions: [ <CurrentReportFilterForm siteFilter={{isVisible: false}} dateRangeSelector={{visibleOptions: ['lastMonth', 'lastWeek']}} /> ]
	}
});
