import React from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

// Import local Components.
import { CurrentReportContext } from 'src/components/shared/context/CurrentReport';
import { ReportingPeriodOption, ReportingPeriodOptionKey } from 'src/components/shared/context/CurrentReport/exports.types';

// Import local styles.
import './styles.scss';

export type CurrentReportFilterFormProps = {
	siteFilter?: {
		isVisible: boolean
	},
	dateRangeSelector?: {
		isVisible?: boolean,
		visibleOptions?: ReportingPeriodOptionKey[]
	},
	submitHandler?: {
		isVisible?: boolean,
		isAutoSubmit?: boolean,
		callback?: () => void
	}
}
 
const CurrentReportFilterForm: React.SFC<CurrentReportFilterFormProps> = ({submitHandler, siteFilter, dateRangeSelector}) => {

	// Local contexts.
	const { dispatch, reportingPeriodOpts } = React.useContext(CurrentReportContext);

	// Local state.
	const [activeOption, setActiveOption] = React.useState<ReportingPeriodOption['key']>('lastMonth');

	// Handles form submission and hoists values to parent callback.
	const handleSubmit = (event: any) => {
		const optionsMap = reportingPeriodOpts.reduce((map: any, obj: ReportingPeriodOption) => {
			map[obj.key] = {
				dateFrom: obj.dateFrom,
				dateTo: obj.dateTo,
				metricInterval: obj.metricInterval
			};
			return map;
		}, {});

		dispatch({
			type: 'UPDATE_REPORT_DATE_SETTINGS',
			value: {
				dateFrom: optionsMap[activeOption].dateFrom,
				dateTo: optionsMap[activeOption].dateTo,
				metricInterval: optionsMap[activeOption].metricInterval,
			}
		});

		event.preventDefault();
	};

	// Handles the optional preparation of the list of reporting period options.
	const handleProcessingReportingPeriodOptions = (allowedOptions: ReportingPeriodOption['key'][]) => {
		if (allowedOptions.length == 0) { return reportingPeriodOpts; }
		return reportingPeriodOpts.filter(item => allowedOptions.includes(item.key) ? true : false);
	};

	// Defines default local variables from optional props.
	const showSubmitHandler = (submitHandler && submitHandler.isVisible == false) ? false : true;
	const showSiteFilter = (siteFilter && siteFilter.isVisible == false) ? false : true;
	const dateRangeSelectOptions = (dateRangeSelector && dateRangeSelector.visibleOptions)
		? handleProcessingReportingPeriodOptions(dateRangeSelector.visibleOptions)
		: reportingPeriodOpts;

	return (    
		<Form className="form form--current-report-filters float-right" inline onSubmit={handleSubmit}>
			{ showSiteFilter &&
				<FormGroup>
					<Label className="sr-only" for="customerSite">Customer Site</Label>
					<Input type="select" name="customerSite" id="customerSite" disabled>
						<option>Primary Site [Default]</option>
					</Input>
				</FormGroup>
			}
			<FormGroup>
				<Label className="sr-only" for="samplingPeriod">Sampling Period</Label>
				<Input
					type="select"
					name="samplingPeriod"
					id="samplingPeriod"
					value={activeOption}
					onChange={e => setActiveOption(e.target.value)}
				>
					{
						dateRangeSelectOptions.map(item => (
							<option
								key={item.key}
								value={item.key}
							>
								{item.label}
							</option>
						)
						)}
				</Input>
			</FormGroup>
			{ showSubmitHandler && 
				<FormGroup>
					<Button type="submit" color="primary">Update Report</Button>
				</FormGroup>
			}
		</Form>
	);
};
 
export default CurrentReportFilterForm;