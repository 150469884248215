import React from 'react';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import { LegendProps } from '@nivo/legends';
import { CallStatisticsReport } from 'src/components/shared/hooks/useCallStatistics/exports.types';
import { CallStatisticsTooltip } from 'src/components/shared/GraphTooltips';
import { GraphResultsMissingAlert, GraphResultsLoadingAlert } from 'src/components/shared/Alerts';

import './styles.scss';

export interface CallQOSStatisticsGraphProps {
	isLoading: boolean,
  data: CallStatisticsReport[] | null
}

const CallQOSStatisticsGraph: React.FC<
  CallQOSStatisticsGraphProps
> = ({ isLoading, data: report }) => {

	const legends: ({
    dataFrom: 'keys' | 'indexes'
	} & LegendProps)[] = [
		{
			dataFrom: 'keys',
			anchor: 'top-left',
			direction: 'row',
			justify: false,
			itemWidth: 100,
			itemHeight: 10,
			itemsSpacing: 10,
			symbolSize: 10,
			symbolSpacing: 10,
			symbolShape: 'circle',
			translateY: -20
		}
	];

	if (isLoading) {
		return <GraphResultsLoadingAlert />;
	}

	if (report && !report.length) {
		return <GraphResultsMissingAlert />;
	}
	
	return (
		<div className='graph graph--call-qos-statistics'>
			<ResponsiveBar
				animate={true}
				axisLeft={{
					tickSize: 0,
					tickValues: 4,
				}}
				axisBottom={{
					tickPadding: 10,
					format: d => moment(d).format('MM/DD'),
					tickSize: 5,
				}}
				borderRadius={3}
				data={report || []}
				colors={
					[
						'rgba(66, 149, 119, .5)',
						'rgba(255, 190, 101, .5)',
						'rgba(202, 80, 87, .5)'
					]
				}
				enableLabel={false}
				enableGridY={false}
				groupMode='grouped'
				indexBy='timestamp'
				keys={['good', 'marginal', 'poor']}
				legends={legends}
				margin={{ top: 40, right: 0, bottom: 80, left: 40 }}
				maxValue='auto'
				motionStiffness={90}
				motionDamping={15}
				minValue={0}
				padding={0.2}
				theme={{
					legends: {
						text: {
							fontSize: 12,
							fontFamily: '\'Avenir\', sans-serif',
							fontWeight: 900,
							textTransform: 'uppercase',
						}
					}
				}}
				tooltip={(props) => (<CallStatisticsTooltip {...props} />)}
			/>
		</div>
	);
};

export default CallQOSStatisticsGraph;
