import React from 'react';
import { Column, TableCellRenderer } from 'react-table';
import moment from 'moment';

import { DataTable } from 'src/components/shared/data-table';

export interface PoorCallsByDateDataTableProps {
	isLoading: boolean,
	report: any,
	reactTableRef: React.RefObject<any>
}

const PoorCallsByDateDataTable: React.FC<
	PoorCallsByDateDataTableProps
> = ({ isLoading, report, reactTableRef }) => {


	// Custom cell renderer for the timestamp field.
	const CellTimestamp: TableCellRenderer = ({value}) => {
		const date = moment(value);
		return (
		<>
			<span className="datetime__date">{date.format('YYYY/MM/DD')}</span>
			<span className="datetime__time" style={{marginLeft: '1ch', opacity: .5}}>{date.format('HH:mm:ss')}</span>
		</>
		);
	};

	const tableColumns: Column[] = [
		{
			Header: 'Date',
			id: 'timestamp',
			accessor: d => d.timestamp,
			Cell: CellTimestamp,

		},
		{
			Header: 'nV: In',
			id: 'nvin',
			accessor: d => d.nVNet.in,
		},
		{
			Header: 'nV: Out',
			id: 'nvout',
			accessor: d => d.nVNet.out
		},
		{
			Header: 'AC: In',
			id: 'acin',
			accessor: d => d.access.in
		},
		{
			Header: 'AC: Out',
			id: 'acout',
			accessor: d => d.access.out
		},
		{
			Header: 'Local: In',
			id: 'locin',
			accessor: d => d.local.in
		},
		{
			Header: 'Local: Out',
			id: 'locout',
			accessor: d => d.local.out
		}
	];

	return (
		<div className='pcb-date-data-table'>
			<DataTable
				data={report}
				className='data-table data-table--pcb-date'
				columns={tableColumns}
				loading={isLoading}
				reactTableRef={reactTableRef}
			/>
		</div>
	);
};

export default PoorCallsByDateDataTable;
