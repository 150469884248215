import React from 'react';
const MSIPIcon: React.FC = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16.75" fill="white">
		<g transform="translate(-190.999 -1714.997)">
			<g transform="translate(190.999 1714.997)">
				<path d="M16.061,16.747H7.977v-4.04a1.558,1.558,0,1,0-1.2.015v4.024H5.4A5.426,5.426,0,0,1,.423,13.433a5.387,5.387,0,0,1-.315-1.016A5.428,5.428,0,0,1,2.6,6.7a5.372,5.372,0,0,1,1.714-.676c0-.042,0-.086,0-.131A5.94,5.94,0,0,1,4.433,4.7a5.853,5.853,0,0,1,.886-2.106A5.925,5.925,0,0,1,6.911,1,5.862,5.862,0,0,1,9.017.117a5.933,5.933,0,0,1,2.989.161,5.906,5.906,0,0,1,3.7,3.51A4.173,4.173,0,0,1,17.2,3.514a4.228,4.228,0,0,1,.844.085,4.163,4.163,0,0,1,1.5.63A4.2,4.2,0,0,1,21.06,6.071a4.156,4.156,0,0,1,.244.786,4.21,4.21,0,0,1,.085.844c0,.114,0,.229-.014.342a4.571,4.571,0,0,1-.166,8.345,4.528,4.528,0,0,1-.857.266,4.6,4.6,0,0,1-.921.093H17.258V12.707a1.558,1.558,0,1,0-1.2.015v4.024ZM12,7.32a1.561,1.561,0,0,0-1.286,2.445,1.565,1.565,0,0,0,.705.565v4.116a.6.6,0,0,0,1.2,0V10.315A1.56,1.56,0,0,0,12,7.32Z" transform="translate(0.001 0.003)"/>
			</g>
		</g>
	</svg>
);

export default MSIPIcon;
