import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './styles.scss';

import { StackedMetricField } from '../StackedMetricField';
import { StackedMetricFieldProps } from '../StackedMetricField/StackedMetricField';

export interface ServiceSnapshotProps {
  serviceTitle?: string,
  buttonLabel: string,
  buttonLink: string,
  className?: string,
  metrics: StackedMetricFieldProps[]
}

const ServiceSnapshot: React.SFC<ServiceSnapshotProps> = ({
	buttonLabel,
	buttonLink,
	className,
	metrics,
	serviceTitle
}) => {
	const defaultClassName = 'service-metric-overview';
	
	const containerClassNames: string = classNames(defaultClassName, className ? className : '');
	
	const titleClassNames: string = classNames(
		`${defaultClassName}__title`,
		className ? `${className}__title` : ''
	);

	const metricsClassNames: string = classNames(
		`${defaultClassName}__metrics`,
		className ? `${className}__metrics` : ''
	);

	const actionClassNames: string = classNames(
		`${defaultClassName}__action`,
		className ? `${className}__action` : ''
	);

	const buttonClassNames: string = classNames(
		`${defaultClassName}__button`,
		className ? `${className}__button` : ''
	);

	return (
		<div className={containerClassNames}>
			{ serviceTitle &&
				<h3 className={titleClassNames}>{serviceTitle}</h3>
			}
			<div className={metricsClassNames}>
				{metrics.map((metric, key) => (
					<StackedMetricField {...metric} key={key} />
				))}
			</div>
			<div className={actionClassNames}>
				<Link className={buttonClassNames} to={buttonLink}>
					{buttonLabel}
				</Link>
			</div>
		</div>
	);
};

export default ServiceSnapshot;
