import * as React from 'react';
import { RowInfo } from 'react-table';

export const TableRowExpansion: React.SFC<RowInfo> = ({ original }) => {
	const { incident, circuit, outageReason, unavailabilityReason, ...outage } = original;

	// Filters an object to remove unnecessary attributes.
	const filterDetailsObject = (obj: object, exclude: string[]): string[] => {
		return Object.keys(obj)
			.filter(item => !exclude.includes(item));
	};

	const incidentAttributes = filterDetailsObject(incident, ['incidentCategory', 'accountId']);
	const outageAttributes = filterDetailsObject(outage, ['account', 'createDateTime', 'updateDateTime', 'flagError', 'statementId', 'slaCategory']);
	const outageReasonAttributes = filterDetailsObject(outageReason, []);
	const circuitAttributes = filterDetailsObject(circuit, []);
	const unavailabilityReasonAttributes = filterDetailsObject(unavailabilityReason, []);

	return (
		<div className="rt-tr-expansion">
			<div className="details outage-details">
				<h5>Outage Details</h5>
				<dl>
					{outageAttributes.map(item => {
						return (
							<React.Fragment key={item}>
								<dt>{item.endsWith('Id') ? 'ID' : item}:</dt>
								<dd>{outage[item] === null ? '(Unavailable)' : outage[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details outage-reason-details">
				<h5>Outage Reason</h5>
				<dl>
					{outageReasonAttributes.map(item => {
						return (
							<React.Fragment key={item}>
								<dt>{item.endsWith('Id') ? 'ID' : item}:</dt>
								<dd>{outageReason[item] === null ? '(Unavailable)' : outageReason[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details incident-details">
				<h5>Incident Details</h5>
				<dl>
					{incidentAttributes.map(item => {
						return (
							<React.Fragment key={item}>
								<dt>{item.endsWith('Id') ? 'ID' : item}:</dt>
								<dd>{incident[item] === null ? '(Unavailable)' : incident[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details incident-details">
				<h5>Incident Category Details:</h5>
				<dl>
					{incidentAttributes.map(item => {
						return (
							<React.Fragment key={item}>
								<dt>{item.endsWith('Id') ? 'ID' : item}:</dt>
								<dd>{incident[item] === null ? '(Unavailable)' : incident[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details unavailability-reason-details">
				<h5>Unavailability Reason Details</h5>
				<dl>
					{unavailabilityReasonAttributes.map(item => {
						return (
							<React.Fragment key={item}>
								<dt>{item.endsWith('Id') ? 'ID' : item}:</dt>
								<dd>{unavailabilityReason[item] === null ? '(Unavailable)' : unavailabilityReason[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details circuit-details">
				<h5>Circuit Details</h5>
				<dl>
					{circuitAttributes.map(item => {
						return (
							<React.Fragment key={item}>
								<dt>{item.endsWith('Id') ? 'ID' : item}:</dt>
								<dd>{circuit[item] === null ? '(Unavailable)' : circuit[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl>
			</div>
			<div className="details sla-details">
				<h5>SLA Details</h5>
				{/* <dl>
					{ eventTypeAttributes.map( item => {
						return (
							<React.Fragment key={item}>
								<dt>{item.endsWith('Id') ? 'ID' : item}:</dt>
								<dd>{eventType[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl> */}
			</div>
			<div className="details statement-details">
				<h5>Statement Details</h5>
				{/* <dl>
					{ eventTypeAttributes.map( item => {
						return (
							<React.Fragment key={item}>
								<dt>{item.endsWith('Id') ? 'ID' : item}:</dt>
								<dd>{eventType[item]}</dd>
							</React.Fragment>
						);
					})}
				</dl> */}
			</div>
		</div>
	);
};

export default TableRowExpansion;