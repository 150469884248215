import * as React from 'react';
import moment from 'moment';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';

// Import local Components.
import { CallConcurrencyReportTransform } from 'src/components/shared/hooks/useCallConcurrency/exports.types';
import ConcurrencyTooltip from './ConcurrencyTooltip';
import { GraphResultsMissingAlert } from 'src/components/shared/Alerts';

export type ConcurrencyGraphProps = {
  reports: CallConcurrencyReportTransform[] | null
}

export const ConcurrencyGraph: React.SFC<ConcurrencyGraphProps> = ({reports}) => {
	const [ activeTab, setActiveTab ] = React.useState(2);

	// Sorts array of reports by timestamp.
	const sortByTimestamp = (a: CallConcurrencyReportTransform, b: CallConcurrencyReportTransform) => {
		if (a.timestamp === b.timestamp) {
			return 0;
		}
		return moment(a.timestamp).diff(moment(b.timestamp));
	};

	// Transforms reports for linear graph format.
	const prepareLineChartData = (reports: CallConcurrencyReportTransform[]) => {
		let formatted = reports
		// .sort((a, b) => sortByTimestamp(a, b))
			.map(item => {
				return {
					timestamp: moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
					y: item.calls,
				};
			});
		return [{
			id: 'concurrency',
			data: formatted
		}];
	};
  
	// Transforms reports for bar graph format.
	const prepareBarChartData = (reports: CallConcurrencyReportTransform[]) => {
		let formatted = reports
			.sort((a, b) => sortByTimestamp(a, b))
			.map(item => {
				return {
					timestamp: moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
					calls: item.calls,
				};
			});
		return formatted;
	};

	const commonProperties = {
		margin: { top: 40, right: 0, bottom: 40, left: 20 },
		animate: true,
	};
  
	if (reports && reports.length < 1) {
		return <GraphResultsMissingAlert />;
	}

	return (  
		<>
      {/* Disabling dynamic chart toggle until a future date.
        <Nav pills className="justify-content-end" style={{width: '100%', maxWidth: '100%'}}>
          <NavItem>
            <NavLink
              className={activeTab === 1 ? 'active' : ''}
              onClick={() => setActiveTab(1)}
            >
              Line Chart
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 2 ? 'active' : ''}
              onClick={() => setActiveTab(2)}
            >
              Bar Chart
            </NavLink>
          </NavItem>
        </Nav>
      */}
			<TabContent activeTab={activeTab}>
				{/* Disabling dynamic chart toggle until a future date.
          <TabPane tabId={1}>
            <div
              style={{height: 300}}
            > 
              <ResponsiveLine
                {...commonProperties}
                data={reports ? [] : []}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%d %H:%M:%S', // Uses D3.js timeformat
                  precision: 'second',
                }}
                xFormat="time:%Y-%m-%d"
                yScale={{
                  type: 'linear',
                  stacked: false,
                  min: 0,
                  max: 'auto',
                }}
                axisLeft={{
                  legend: 'Calls',
                  legendOffset: -40,
                  legendPosition: 'middle',
                }}
                axisBottom={{
                  legend: 'Date/Time (UTC)',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  format: '%b %d',
                }}
                curve="linear"
                colors="rgb(102,90,127)"
                lineWidth={2}
                pointSize={8}
                pointBorderWidth={1}
                pointBorderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.3]],
                }}
                useMesh={true}
                enableGridX={false}
                enableSlices="x"
                sliceTooltip={(data: any) => {
                  const { slice: { points: [ point ] } } = data;
                  return (
                    <div style={{
                      padding: 10,
                      opacity: .95,
                      color: '#fff',
                      background: point.color ? point.color : '#333',
                    }}>
                      <div className="d-flex justify-content-between">
                        <span style={{ fontWeight: 700, textTransform: 'capitalize', marginRight: 10}}>Date:</span>
                        <span>{moment.utc(point.data.x).format('DD/MM/YYYY')}</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span style={{ fontWeight: 700, textTransform: 'capitalize', marginRight: 10}}>Time:</span>
                        <span>{moment.utc(point.data.x).format('HH:mm:ss')}</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span style={{ fontWeight: 700, textTransform: 'capitalize', marginRight: 10}}>Delay:</span>
                        <span>{`${point.data.yFormatted}ms`}</span>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </TabPane>
        */}
				<TabPane tabId={2}>
					<div className="graph graph--status-report-concurrency">
						<ResponsiveBar
							{...commonProperties}
							data={reports ? prepareBarChartData(reports) : []}
							keys={['calls']}
							indexBy='timestamp'
							padding={0.1}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor="inherit:darker(1.6)"
							animate={true}
							maxValue='auto'
							minValue={0}
							motionStiffness={90}
							motionDamping={15}
							axisLeft={{
								tickSize: 0,
								tickValues: 4,
							}}
							axisBottom={{
								tickPadding: 10,
								format: d => moment(d).format('MM/DD'),
								tickSize: 5,
							}}
							borderRadius={3}
							colors={
								[
									'rgba(102,90,127, .75)'
								]
							}
							enableLabel={false}
							enableGridY={false}
							tooltip={(props) => (<ConcurrencyTooltip {...props} />)}
						/>
					</div>
				</TabPane>
			</TabContent>
		</>
	);
};

export default ConcurrencyGraph;