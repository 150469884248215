import React from 'react';
import VisibilityBarChart from '../VisibilityBarChart';
import { CallStatisticsTooltip } from 'src/components/shared/GraphTooltips';

export interface PoorCallsResponsibleSegmentProps {
	isLoading: boolean,
  data: {
    [key: string]: any
  }[] | null,
	dateFrom: Date,
	dateTo: Date
}

const PoorCallsResponsibleSegment: React.FC<PoorCallsResponsibleSegmentProps> = ({ isLoading, data, dateFrom, dateTo }) => {
	return (
		<VisibilityBarChart
			isLoading={isLoading}
			colors={['rgba(78, 167, 218, 0.5)', 'rgba(151, 153, 155, 0.5)']}
			borderRadius={0}
			data={data || []}
			dateFrom={dateFrom}
			dateTo={dateTo}
			graphTitle='Segment Responsible for Poor Calls'
			graphTitleColor="rgba(78, 167, 218, 1)"
			keys={['nVNet', 'access', 'local']}
			tooltip={props => <CallStatisticsTooltip {...props} />}
		/>
	);
};
 
export default PoorCallsResponsibleSegment;