import * as React from 'react';
import { match } from 'react-router';
import { Row, Container, Col } from 'reactstrap';
import { PageComponentProps } from '@nexvortex/page';
import AccountActivity from '../../Blocks/AccountActivity';

export interface LogsPageProps {}
 
const LogsPage: React.FC<LogsPageProps & PageComponentProps> = ({match}) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container
			fluid
			className="page page--msip-logs"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>Logs</h2>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<AccountActivity accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default LogsPage;