import * as React from 'react';
import { Row, Container, Col } from 'reactstrap';
import { match } from 'react-router';
import { PageComponentProps } from '@nexvortex/page';
import QoSReportsNavigationToggle from '../../Blocks/QoSReportsNavigationToggle';
import { TotalCalls } from '../../Blocks/TotalCalls';

export interface TotalCallsPageProps {}
 
const TotalCallsPage: React.FC<TotalCallsPageProps & PageComponentProps> = ({match}) => {
	
	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container
			fluid
			className="page page--msip-call-qos-statistics"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>Total Calls Report</h2>
					<QoSReportsNavigationToggle currentPageTitle="Total Calls" />
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<TotalCalls accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default TotalCallsPage;