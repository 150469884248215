import * as React from 'react';
import { match } from 'react-router';
import { PageComponentProps } from '@nexvortex/page';
import { Row, Container, Col } from 'reactstrap';
import QoSReportsNavigationToggle from 'src/components/features/Blocks/QoSReportsNavigationToggle';
import { PoorAndMarginalCalls } from 'src/components/features/Blocks/PoorAndMarginalCalls';

export interface PoorAndMarginalCallsPageProps {}

const PoorAndMarginalCallsPage: React.FC<
  PoorAndMarginalCallsPageProps & PageComponentProps
> = ({match}) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container fluid className='page page--msip-poor-and-marginal-calls'>
			<Row tag='header'>
				<Col lg={12}>
					<h2>POOR AND MARGINAL CALLS</h2>
					<QoSReportsNavigationToggle currentPageTitle='Poor - Marginal' />
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<PoorAndMarginalCalls accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default PoorAndMarginalCallsPage;
