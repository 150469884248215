import * as React from 'react';
import { match } from 'react-router';
import { PageComponentProps } from '@nexvortex/page';
import { Row, Container, Col } from 'reactstrap';
import { default as StatusReports } from '../../Blocks/StatusReports';

export interface StatusReportsPageProps {}
 
const StatusReportsPage: React.FC<StatusReportsPageProps & PageComponentProps> = ({match}) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container
			fluid
			className="page page--msip-status-reports"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>Status Reports</h2>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<StatusReports accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default StatusReportsPage;