import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { BarExtendedDatum } from '@nivo/bar';
import { CallAverageBySegmentReport } from 'src/components/shared/hooks/useCallAverageBySegment/exports.types';

import './styles.scss';

const AverageCallQualityTooltipMetric: React.FC<{label: string; value: any}> = ({label, value}) => {
	const className = `metric--${label.toLowerCase()}`;
	const transformedValue = (typeof value === 'string' || value instanceof String) ? value : value.toFixed(2);

	return (
		<div className={classNames('metric', className)}>
			<span className="metric__label">{label}</span>
			<span className="metric__value">{transformedValue}</span>
		</div>
	);
};

const AverageCallQualityTooltipMetrics: React.FC<{poor: number | null; average: number | null; total: number | null}> = ({poor, average, total}) => {
	return (
		<div className="tooltip__metrics">
			<AverageCallQualityTooltipMetric label="Poor" value={poor || '--'} />
			<AverageCallQualityTooltipMetric label="Total" value={total || '--'} />
			<AverageCallQualityTooltipMetric label="Average" value={average || '--'} />
		</div>
	);
};

const AverageCallQualityTooltipSegment: React.FC<{name: string; inbound: number; outbound: number}> = ({name, inbound, outbound}) => {
	const className = `segment__name--${name.toLowerCase()}`;
	return (
		<div className="segment">
			<span className={classNames('segment__name', className)}>{name}</span>
			<dl className="segment__metric">
				<dt className="metric__label">In</dt>
				<dd className="metric__value">{inbound ? inbound.toFixed(2) : '--'}</dd>
			</dl>
			<dl className="segment__metric">
				<dt className="metric__label">Out</dt>
				<dd className="metric__value">{outbound ? outbound.toFixed(2) : '--'}</dd>
			</dl>
		</div>
	);
};

const AverageCallQualityTooltipSegments: React.FC<{segments: {name: string; in: number; out: number}[]}> = ({segments}) => {
	return (
		<div className="tooltip__metrics-breakdown">
			{ segments.map(({name, in: inbound, out: outbound}, index) => (
				<AverageCallQualityTooltipSegment key={index} name={name} inbound={inbound} outbound={outbound} />
			))
			}
		</div>
	);
};

const AverageCallQualityTooltip: React.FC<{data: Partial<CallAverageBySegmentReport>} & BarExtendedDatum> = ({color, data, id, ...rest}) => {
	const { timestamp } = data;
	const poor = data.poor as number;
	const total = data.total as number;
	const average = null;

	const parseSegments = (data: Partial<CallAverageBySegmentReport> & {[name: string]: any}) => {
		const allowedSegments: string[] = ['nVNet', 'access', 'local'];
		return allowedSegments.map(name => {
			return {
				name: name,
				...data[name],
			};
		});
	};

	return (  
		<div className="custom-tooltip custom-tooltip--average-call-quality">
			<div className="tooltip__timestamp">
				<h5>
					{ timestamp
						? moment(timestamp).format('MMMM DD, YYYY')
						: 'N/A'
					}
				</h5>
				<h6 style={{textAlign: 'center'}}>
					{ timestamp
						? moment(timestamp).format('HH:mm:ss')
						: 'N/A'
					}
				</h6>
			</div>
			<AverageCallQualityTooltipMetrics poor={poor} total={total} average={average} />
			<AverageCallQualityTooltipSegments segments={parseSegments(data)} />
		</div>
	);
};

export default AverageCallQualityTooltip;