import React from 'react';
import moment from 'moment';
import { BarExtendedDatum } from '@nivo/bar';

import './styles.scss';

const LatencyTooltip: React.FC<BarExtendedDatum> = ({color, data, id, ...rest}) => {
	return (  
		<div className="custom-tooltip custom-tooltip--call-latency">
			{ data.timestamp && 
				<div className="tooltip__timestamp" style={{color}}>
					<h5>{ moment(data.timestamp).format('MMMM DD, YYYY') }</h5>
					<h6>{ moment(data.timestamp).format('HH:mm:ss') }</h6>
				</div>
			}
			<div className="tooltip__metrics">
				<div className="metric metric--target">
					<span className="metric__label">{id}</span>
					<span className="metric__value" style={{color}}>{data[id]}</span>
				</div>
			</div>
		</div>
	);
};

export default LatencyTooltip;