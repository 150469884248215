import * as React from 'react';
import moment from 'moment';
import { reducer } from './exports.reducer';
import { ReportingPeriodOption, CurrentReportInterface } from './exports.types';

// Defines default context value and initial state.
const initialState = {  
	dateFrom: process.env.NODE_ENV === 'development'
		? moment('2018-07-01').toDate()
		: moment().subtract(30, 'days').toDate(),
	dateTo: process.env.NODE_ENV === 'development'
		? moment('2018-07-30').toDate()
		: moment().subtract(1, 'day').toDate(),
	metricInterval: 86400,
};

// Defines reuable set of options for populating report filter forms.
const reportingPeriodOpts: ReportingPeriodOption[] = [
	{
		key: 'lastHour',
		label: 'Last Hour',
		dateFrom: process.env.NODE_ENV === 'development'
			? moment('2018-07-01 09:00:00').toDate()
			: moment().subtract(2, 'hour').toDate(),
		dateTo: process.env.NODE_ENV === 'development'
			? moment('2018-07-01 10:00:00').toDate()
			: moment().subtract(1, 'hour').toDate(),
		metricInterval: 60,
	},
	{
		key: 'lastEightHours',
		label: 'Last 8 Hours',
		dateFrom: process.env.NODE_ENV === 'development'
			? moment('2018-07-01 09:00:00').toDate()
			: moment().subtract(8, 'hours').toDate(),
		dateTo: process.env.NODE_ENV === 'development'
			? moment('2018-07-01 17:00:00').toDate()
			: moment().subtract(1, 'hour').toDate(),
		metricInterval: 3600,
	},
	{
		key: 'lastDay',
		label: 'Last 24 Hours',
		dateFrom: process.env.NODE_ENV === 'development'
			? moment('2018-07-01').toDate()
			: moment().subtract(24, 'hours').toDate(),
		dateTo: process.env.NODE_ENV === 'development'
			? moment('2018-07-02').toDate()
			: moment().subtract(1, 'hour').toDate(),
		metricInterval: 3600,
	},
	{
		key: 'lastWeek',
		label: 'Last Week',
		dateFrom: process.env.NODE_ENV === 'development'
			? moment('2018-07-01').toDate()
			: moment().subtract(7, 'days').toDate(),
		dateTo: process.env.NODE_ENV === 'development'
			? moment('2018-07-08').toDate()
			: moment().subtract(1, 'day').toDate(),
		metricInterval: 86400,
	},
	{
		key: 'lastMonth',
		label: 'Last 30 Days',
		dateFrom: process.env.NODE_ENV === 'development'
			? moment('2018-07-01').toDate()
			: moment().subtract(30, 'days').toDate(),
		dateTo: process.env.NODE_ENV === 'development'
			? moment('2018-07-30').toDate()
			: moment().subtract(1, 'day').toDate(),
		metricInterval: 86400,
	},
];


// Defines new Context.
export const CurrentReportContext = React.createContext<CurrentReportInterface>({
	state: initialState,
	dispatch: null,
	reportingPeriodOpts
});

// Defines Context Consumer.
export const CurrentReportConsumer = CurrentReportContext.Consumer;

// Defines Context Provider.
export const CurrentReportProvider: React.SFC<{}> = ({children, ...rest }) => {
  
	// Uses local state management and initial state.
	const [state, dispatch] = React.useReducer(reducer, initialState);

	return (
		<CurrentReportContext.Provider
			{...rest}
			value={{
				state,
				dispatch,
				reportingPeriodOpts
			}}
		>
			{children}
		</CurrentReportContext.Provider>
	);
};