import React from 'react';
import { Column, TableCellRenderer } from 'react-table';
import moment from 'moment';

import { DataTable } from 'src/components/shared/data-table';

export interface PoorCallsByTimeOfDayDataTableProps {
	isLoading: boolean,
	report: any,
	reactTableRef: React.RefObject<any>
}

const PoorCallsByTimeOfDayDataTable: React.FC<
	PoorCallsByTimeOfDayDataTableProps
> = ({ isLoading, report, reactTableRef }) => {

	const tableColumns: Column[] = [
		{
			Header: 'Time of Day',
			id: 'timestamp',
			accessor: d => moment(d.timestamp).format('HH:00:00'),
		},
		{
			Header: 'nV: In',
			id: 'nvin',
			accessor: d => d.nVNet.in,
		},
		{
			Header: 'nV: Out',
			id: 'nvout',
			accessor: d => d.nVNet.out
		},
		{
			Header: 'AC: In',
			id: 'acin',
			accessor: d => d.access.in
		},
		{
			Header: 'AC: Out',
			id: 'acout',
			accessor: d => d.access.out
		},
		{
			Header: 'Local: In',
			id: 'locin',
			accessor: d => d.local.in
		},
		{
			Header: 'Local: Out',
			id: 'locout',
			accessor: d => d.local.out
		}
	];

	return (
		<div className='pcb-date-data-table'>
			<DataTable
				data={report}
				className='data-table data-table--pcb-date'
				columns={tableColumns}
				defaultPageSize={30}
				showPagination={report.length > 30}
				loading={isLoading}
				reactTableRef={reactTableRef}
			/>
		</div>
	);
};

export default PoorCallsByTimeOfDayDataTable;
