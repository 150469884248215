import React, { useState } from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { withRouter, match } from 'react-router';
import classNames from 'classnames';
import { PageComponentProps } from '@nexvortex/page';

// Import Local Components.
import { withBlock } from 'src/components/shared/Block';
import ConcurrencyContainer from './Concurrency';
import LatencyContainer from './Latency';

// Import Local Styles.
import './styles.scss';
import { CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';

export interface StatusReportsContainerProps {
	accountId: string|number
}
 
const StatusReportsContainer: React.FC<StatusReportsContainerProps & PageComponentProps> = ({ match }) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	// Local state.
	const [activeTab, setActiveTab] = useState<number>(2);

	return (
    <>
			<Nav pills className="nav--status-report-tabs justify-content-center" style={{width: '100%', maxWidth: '100%'}}>
				<NavItem>
					<NavLink
						className={classNames(activeTab === 1 ? 'active' : '')}
						onClick={() => setActiveTab(1)}
					>
						Latency
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classNames(activeTab === 2 ? 'active' : '')}
						onClick={() => setActiveTab(2)}
					>
						Concurrency
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId={1}>
					<LatencyContainer accountId={accountId} />
				</TabPane>
				<TabPane tabId={2}>
					<ConcurrencyContainer accountId={accountId} />
				</TabPane>
			</TabContent>
    </>
	);
};

export default withRouter(
	withBlock(StatusReportsContainer, {
		header: {
			heading: 'Concurrency and Delay Reports',
			actions: [ <CurrentReportFilterForm siteFilter={{isVisible: false}} /> ]
		}
	})
);
