import React from 'react';
import VisibilityBarChart from '../VisibilityBarChart';
import { CallAverageBySegmentReport } from 'src/components/shared/hooks/useCallAverageBySegment/exports.types';
import AverageCallQualityTooltip from './AverageCallQualityTooltip';

export interface AverageCallQualityProps {
	isLoading: boolean,
  data: Partial<CallAverageBySegmentReport>[] | null,
	dateFrom: Date,
	dateTo: Date
}
 
const AverageCallQuality: React.FC<AverageCallQualityProps> = ({ isLoading, data, dateFrom, dateTo }) => {
	return (
		<VisibilityBarChart
			isLoading={isLoading}
			axisLeft={{
				tickPadding: 10,
				tickSize: 0,
				tickValues: 6,
			}}
			colors="rgba(66, 149, 119, 0.5)"
			data={data || []}
			dateFrom={dateFrom}
			dateTo={dateTo}
			graphTitle='Average Call Quality'
			keys={['average']}
			maxValue={5}
			tooltip={(props) => (<AverageCallQualityTooltip {...props} />)}
		/>
	);
};
 
export default AverageCallQuality;