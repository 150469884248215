import * as React from 'react';
import { match } from 'react-router';
import { PageComponentProps } from '@nexvortex/page';
import { Row, Container, Col } from 'reactstrap';
import QoSReportsNavigationToggle from 'src/components/features/Blocks/QoSReportsNavigationToggle';
import { PoorCallsByDate } from 'src/components/features/Blocks/PoorCallsByDate';

import '../styles.scss';

export interface PoorCallsByDatePageProps {}

const PoorCallsByDatePage: React.FC<
PoorCallsByDatePageProps & PageComponentProps
> = ({match}) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container fluid className='page page--msip-poor-calls-by-date'>
			<Row tag='header'>
				<Col lg={12}>
					<h2>Poor Calls By Date</h2>
					<QoSReportsNavigationToggle currentPageTitle='Poor - Date' />
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<PoorCallsByDate accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default PoorCallsByDatePage;
