import React, { useEffect, useRef } from 'react';
import moment from 'moment';

// Import local shared Components.
import { Block } from 'src/components/shared/Block';
import { ExportButton } from 'src/components/shared/buttons';
import { withExportHandler, ExportHOCProps, poorAndMarginalCallsHeadersCSV, poorAndMarginalCallsFilenameCSV } from 'src/components/shared/ExportHandler';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';
import { usePoorMarginalCalls } from 'src/components/shared/hooks/usePoorMarginalCalls';
import { selectPoorMarginalCallsReport, selectPoorMarginalCallsMOSSOffenderScatterplot } from 'src/components/shared/hooks/usePoorMarginalCalls/exports.selectors';

// Import local Components.
import PoorAndMarginalCallsDataTable from './PoorAndMarginalCallsDataTable';
import PoorAndMarginalCallsGraph from './PoorAndMarginalCallsGraph';

export interface PoorAndMarginalCallsProps {
	accountId: number|string
}

const PoorAndMarginalCalls: React.SFC<PoorAndMarginalCallsProps & ExportHOCProps> = ({
	reactTableRef = useRef(null),
	accountId,
}
) => {
	
	// Local custom Hooks.
	const [poorMarginalCalls, fetchPoorMarginalCalls ] = usePoorMarginalCalls({accountId});

	// Local custom Contexts.
	const { state: { dateFrom, dateTo }} = React.useContext(CurrentReportContext);

	// Component lifecycle effects.
	useEffect(() => {
		fetchPoorMarginalCalls({
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
		});
	}, [dateFrom, dateTo]);

	return (
		<Block
			header={{
				heading: 'Poor and Marginal Calls',
				actions: [
					<CurrentReportFilterForm siteFilter={{isVisible: false}} />, 
					<ExportButton reactTableRef={reactTableRef} headersCSV={poorAndMarginalCallsHeadersCSV} filenameCSV={poorAndMarginalCallsFilenameCSV}>EXPORT CSV</ExportButton>
				]
			}}
		>
			<div className='poor-and-marginal-calls'>
				<PoorAndMarginalCallsGraph
					isLoading={poorMarginalCalls.isLoading}
					report={selectPoorMarginalCallsMOSSOffenderScatterplot(poorMarginalCalls) || []}
				/>
				<PoorAndMarginalCallsDataTable
					isLoading={poorMarginalCalls.isLoading}
					report={selectPoorMarginalCallsReport(poorMarginalCalls) || []}
					reactTableRef={reactTableRef}
				/>
			</div>
		</Block>
	);
};

export default withExportHandler(PoorAndMarginalCalls);
