import {PoorMarginalCallsReport,PoorMarginalCallsState } from './exports.types';

// Defines reducer action types.
export type ActionUpdateReportData = {
  type: 'UPDATE_REPORT_DATA',
  value: PoorMarginalCallsReport[]
}

export type ActionLoadingReportData = {
  type: 'LOADING_REPORT_DATA',
  value: boolean
}

export type ActionErrorLoadingReportData = {
  type: 'ERROR_LOADING_REPORT_DATA',
  value: Error
}

export type ActionResetReportData = {
  type: 'RESET_REPORT_DATA',
  value: boolean
}

export type PoorMarginalCallsAction =
  | ActionUpdateReportData
  | ActionLoadingReportData
  | ActionResetReportData
  | ActionErrorLoadingReportData

// Defines reducer functionality.
export const reducer: React.Reducer<Readonly<PoorMarginalCallsState>,PoorMarginalCallsAction> = (state, action) => { 
	switch (action.type) {
	case 'LOADING_REPORT_DATA':
		return {
			...state,
			isLoading: action.value,
		};
	case 'UPDATE_REPORT_DATA':
		return {
			...state,
			isLoading: false,
			report: action.value
		};
	case 'RESET_REPORT_DATA':
		return {
			...state,
			isLoading: false,
			report: null
		};
	case 'ERROR_LOADING_REPORT_DATA':
		return {
			...state,
			isLoading: false,
			error: action.value,
			report: null
		};
	}
};