import React from 'react';
import moment from 'moment';
import { BarExtendedDatum } from '@nivo/bar';

import './styles.scss';

const PoorCallsByDateTooltip: React.FC<BarExtendedDatum> = ({color, data, id, ...rest}) => {
	return (  
		<div className="custom-tooltip custom-tooltip--poor-calls">
			{ data.timestamp && 
				<div className="tooltip__timestamp">
					<h5 style={{color}}>{ moment(data.timestamp).format('MMMM DD, YYYY') }</h5>
					<h6 style={{textAlign: 'center'}}>{ moment(data.timestamp).format('HH:mm:ss') }</h6>
				</div>
			}

			{ data.weekDay && 
				<div className="tooltip__timestamp">
					<h5 style={{color}}>{data.weekDay}</h5>
				</div>
			}
			<div className="tooltip__metrics">
				{ Object.keys(data)
					.filter(item => (item === 'timestamp' || item === 'weekDay') ? false : true)
					.map((item, index) => (
						<div
							key={index}
							className="metric metric--target"
							style={{
								backgroundColor: item === id ? color : 'inherit',
								borderRadius: 3
							}}
						>
							<span className="metric__label" style={{color: item === id ? '#fff' : 'inherit'}}>{item}</span>
							<span className="metric__value" style={{color: item === id ? '#fff' : 'inherit'}}>{data[item]}</span>
						</div>
					))
				}
			</div>
		</div>
	);
};

export default PoorCallsByDateTooltip;