import React from 'react';
import VisibilityBarChart from '../VisibilityBarChart';
import { CallStatisticsReport } from 'src/components/shared/hooks/useCallStatistics/exports.types';
import { CallStatisticsTooltip } from 'src/components/shared/GraphTooltips';

export interface GoodCallsProps {
	isLoading: boolean,
	data: Partial<CallStatisticsReport>[] | null,
	dateFrom: Date,
	dateTo: Date
}
 
const GoodCalls: React.FC<GoodCallsProps> = ({ isLoading, data, dateFrom, dateTo }) => {
	return (
		<VisibilityBarChart
			isLoading={isLoading}
			colors="rgba(66, 149, 119, 0.5)"
			data={data || []}
			dateFrom={dateFrom}
			dateTo={dateTo}
			graphTitle='Good Calls'
			graphTitleColor='rgba(66, 149, 119, 1)'
			keys={['good']}
			tooltip={(props) => (<CallStatisticsTooltip {...props} />)}
		/>
	);
};
 
export default GoodCalls;