import React, { useContext, useEffect } from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import moment from 'moment';

// Import Local Components.
import { withBlock } from 'src/components/shared/Block';
import { AvailabilityOverview } from '../AvailabilityOverview/AvailabilityOverview';

// Import Local Styles.
import './styles.scss';
import { DataTable } from 'src/components/shared/data-table';
import { CurrentReportFilterForm, CurrentReportContext } from 'src/components/shared/context/CurrentReport';
import { useSEERIncidents } from 'src/components/shared/hooks/useSEERIncidents';
import { IncidentReportsDataTable } from './IncidentReports';
import { selectSEERIncidentsReport } from 'src/components/shared/hooks/useSEERIncidents/exports.selectors';

export interface AvailabilityReportsContainerProps {
	accountId: number | string
}
 
const AvailabilityReportsContainer: React.FC<AvailabilityReportsContainerProps> = ({accountId}) => {
	
	// Local state.
	const [activeTab, setActiveTab] = React.useState(2);

	// Local custom Hooks.
	const [incidents, fetchIncidents] = useSEERIncidents({accountId});

	// Local contexts.
	const {state: {dateFrom, dateTo}} = useContext(CurrentReportContext);

	useEffect(() => {
		const fetchData = async () => {
			fetchIncidents({
				dateFrom: moment(dateFrom).toISOString(),
				dateTo: moment(dateTo).toISOString(),
				metricInterval: 86400,
			});
		};

		fetchData();
	}, [dateFrom, dateTo]);

	if (!incidents) {
		return null;
	}

	return (
    <>
    <Nav pills className="nav--availability-report-tabs justify-content-center" style={{width: '100%', maxWidth: '100%'}}>
    	<NavItem>
    		<NavLink
    			className={activeTab === 1 ? 'active' : ''}
    			onClick={() => setActiveTab(1)}
    		>
          Availability
    		</NavLink>
    	</NavItem>
    	<NavItem>
    		<NavLink
    			className={activeTab === 2 ? 'active' : ''}
    			onClick={() => setActiveTab(2)}
    		>
          Incidents
    		</NavLink>
    	</NavItem>
    </Nav>
    <TabContent activeTab={activeTab}>
    	<TabPane tabId={1}>
    		<div style={{marginTop: 40, maxWidth: '66%', marginLeft: 'auto', marginRight: 'auto'}}>
    			<DataTable
    				data={[]}
    				columns={[{
    					Header: 'Date',
    					id: 'date'
    				},{
    					Header: 'Call Tag',
    					id: 'callTag'
    				},{
    					Header: 'Dialed',
    					id: 'dialed'
    				},{
    					Header: 'From',
    					id: 'from'
    				},{
    					Header: 'Error Reason',
    					id: 'reason'
    				}]}
    			/>
    		</div>
    	</TabPane>
    	<TabPane tabId={2}>
    		<IncidentReportsDataTable isLoading={incidents.isLoading} report={selectSEERIncidentsReport(incidents)} />
    	</TabPane>
    </TabContent>
    </>
	);
};
 
export default withBlock(AvailabilityReportsContainer, { 
	header: {
		heading: 'Select a Report Option',
		actions: [
			<CurrentReportFilterForm siteFilter={{isVisible: false}} />
		]
	}
});