import React from 'react';
import { Column } from 'react-table';
import moment from 'moment';

// Import local shared Components.
import { DataTable } from 'src/components/shared/data-table';
import { PoorMarginalCallsReport } from 'src/components/shared/hooks/usePoorMarginalCalls/exports.types';

// Import local styles.
import './styles.scss';

export interface PoorAndMarginalCallsDataTableProps {
	isLoading: boolean,
	report: PoorMarginalCallsReport[],
	reactTableRef: React.RefObject<any>
}

const PoorAndMarginalCallsDataTable: React.SFC<PoorAndMarginalCallsDataTableProps> = ({ isLoading, report, reactTableRef }) => {
	
	// Defines data table columns.
	const columns: Column<PoorMarginalCallsReport>[] = [
		{
			Header: '',
			columns: [
				{
					Header: 'Date',
					id: 'timestamp',
					accessor: d => moment(d.timestamp).format('MM/DD/YYYY HH:mm:ss')
				},
				{
					Header: 'SIP Date',
					id: 'sipdate',
					accessor: d => d.sipDate,
				},
				{
					Header: 'Dir',
					id: 'dir',
					accessor: d => d.direction,
				},
				{
					Header: 'Tag',
					id: 'callTag',
					accessor: d => d.callTag ? d.callTag : '(Unavailable)',
				},
			]
		},
		{
			Header: (props) =>
				<div className="poor-and-marginal-calls--grouped-header mos--header-background">
					<span className="poor-and-marginal-calls--grouped-header-text">MOS</span>
				</div>,
			columns: [
				{
					Header: 'NVIN',
					id: 'mos/nvin',
					accessor: d => d.mos.nVNet.in
				},
				{
					Header: 'NVOUT',
					id: 'mos/nvout',
					accessor: d => d.mos.nVNet.out
				},
				{
					Header: 'ACIN',
					id: 'mos/acin',
					accessor: d => d.mos.access.in
				},
				{
					Header: 'ACOUT',
					id: 'mos/acout',
					accessor: d => d.mos.access.out
				},
				{
					Header: 'LOCIN',
					id: 'mos/locin',
					accessor: d => d.mos.local.in
				},
				{
					Header: 'LOCOUT',
					id: 'mos/locout',
					accessor: d => d.mos.local.out
				},]
		},
		{
			Header: (props) =>
				<div className="poor-and-marginal-calls--grouped-header loss--header-background">
					<span className="poor-and-marginal-calls--grouped-header-text">loss</span>
				</div>,
			columns: [
				{
					Header: 'NVIN',
					id: 'loss/nvin',
					accessor: d => d.loss.nVNet.in
				},
				{
					Header: 'NVOUT',
					id: 'loss/nvout',
					accessor: d => d.loss.nVNet.out
				},
				{
					Header: 'ACIN',
					id: 'loss/acin',
					accessor: d => d.loss.access.in
				},
				{
					Header: 'ACOUT',
					id: 'loss/acout',
					accessor: d => d.loss.access.out
				},
				{
					Header: 'LOCIN',
					id: 'loss/locin',
					accessor: d => d.loss.local.in
				},
				{
					Header: 'LOCOUT',
					id: 'loss/locout',
					accessor: d => d.loss.local.out
				},]
		}
	];

	return (
		<div className='poor-and-marginal-calls--data-table'>
			<DataTable
				data={report}
				className='data-table data-table--poor-and-marginal-calls'
				columns={columns}
				pageSize={report.length > 30 ? 30 : 5}
				showPagination={report.length > 30}
				loading={isLoading}
				reactTableRef={reactTableRef}
			/>
		</div>
	);
};

export default PoorAndMarginalCallsDataTable;
