import { CurrentReportState } from './exports.types';

// Defines reducer action types.
export type ActionUpdateReportSettings = {
  type: 'UPDATE_REPORT_DATE_SETTINGS',
  value: Partial<CurrentReportState>
}

export type ActionUpdateReportDateFrom = {
  type: 'UPDATE_REPORT_DATE_FROM',
  value: CurrentReportState['dateFrom']
}

export type ActionUpdateReportDateTo = {
  type: 'UPDATE_REPORT_DATE_TO',
  value: CurrentReportState['dateTo']
}

export type ActionUpdateReportMetricInterval = {
  type: 'UPDATE_REPORT_METRIC_INTERVAL',
  value: CurrentReportState['metricInterval']
}

export type CurrentReportAction = ActionUpdateReportSettings
	| ActionUpdateReportDateFrom
	| ActionUpdateReportDateTo
	| ActionUpdateReportMetricInterval

// Defines reducer functionality.
export const reducer: React.Reducer<Readonly<CurrentReportState>, CurrentReportAction> = (state, action) => { 
	switch (action.type) {
	case 'UPDATE_REPORT_DATE_SETTINGS':
		return {
			...state,
			...action.value,
		};
	case 'UPDATE_REPORT_DATE_FROM':
		return {
			...state,
			dateFrom: action.value,
		};
	case 'UPDATE_REPORT_DATE_TO':
		return {
			...state,
			dateTo: action.value,
		};
	case 'UPDATE_REPORT_METRIC_INTERVAL':
		return {
			...state,
			metricInterval: action.value,
		};
	}
};