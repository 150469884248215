import React from 'react';
import classNames from 'classnames';
import './styles.scss';
export interface BlockWithIconProps {
  content: () => React.ReactNode,
  icon: () => React.ReactNode,
	title: string,
	className? : string
}

const IconListItem: React.FC<BlockWithIconProps> = ({
	content,
	icon,
	title,
	className
}) => {
	const defaultClassName = 'icon-list-item';
	return (
		<div className={classNames(defaultClassName, className ? className : '')}>
			<div className='icon-list-item__icon'>
				<div className='icon-list-item--icon__circle'>{icon()}</div>
			</div>
			<div className='icon-list-item__content'>
				<h3>{title}</h3>
				{content()}
			</div>
		</div>
	);
};

export default IconListItem;
