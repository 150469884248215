import * as React from 'react';
import { useRef } from 'react';

export interface ExportHOCProps {
	reactTableRef?: React.RefObject<any>
}

export const withExportHandler = <
	InnerProps extends object
>(
		InnerComponent: React.ComponentType<InnerProps>
	): React.FC<InnerProps> => (props) => {

		let reactTableRef: React.RefObject<any> = useRef(null);

		props = {
			reactTableRef,
			...props
		};

		return (
			<InnerComponent {...props as InnerProps} />
		);

	};
