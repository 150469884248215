import * as React from 'react';
import { SupportMenu } from '../../Blocks/SupportMenu';
import { PageComponentProps } from '@nexvortex/page';
import { Row, Container, Col } from 'reactstrap';

export type SupportProps = {}

const Support: React.FC<SupportProps & PageComponentProps> = (props) => {
	return (
		<Container
			fluid
			className="page page--msip-support"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>Support</h2>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<SupportMenu />
				</Col>
			</Row>
		</Container>
	);
};

export default Support;
