import React from 'react';
import { Alert } from 'reactstrap';

// Import local shared Components.
import { withBlock } from 'src/components/shared/Block';
import { ServiceSnapshot } from 'src/components/shared/ServiceSnapshot';
import { StackedMetricFieldProps } from 'src/components/shared/StackedMetricField/StackedMetricField';

export interface AvailabilityOverviewContainerProps {}
 
const AvailabilityOverviewContainer: React.SFC<AvailabilityOverviewContainerProps> = () => {

	return (
		<div className="d-flex justify-content-center" style={{paddingTop: 40, paddingBottom: 40, marginTop: 20, marginBottom: 20 }}>
			<Alert color="danger">
				<strong>Sorry!</strong> This feature is not yet available for general access. Check back soon!
			</Alert>
		</div>
	);

	// TODO: Enable when availability reports endpoint exists.
	// const availabilitySnapshotMetrics: StackedMetricFieldProps[] = [
	// 	{
	// 		label: 'Service Availability',
	// 		value: '100',
	// 		percent: true,
	// 		color: 'success',
	// 		suffix: 'Last 30 days',
	// 	},
	// 	{
	// 		label: 'Incidents',
	// 		value: '0',
	// 		percent: false,
	// 		color: 'success',
	// 		suffix: 'Last 30 days',
	// 	},
	// 	{
	// 		label: 'Unavailability',
	// 		value: '00:00',
	// 		percent: false,
	// 		color: 'success',
	// 		suffix: 'Total Service',
	// 	},
	// ];

	// return (
	// 	<ServiceSnapshot
	// 		metrics={availabilitySnapshotMetrics}
	// 		buttonLabel="View Reports"
	// 		buttonLink="reports/availability"
	// 	/>
	// )
};

export interface AvailabilityOverviewProps {
  availability: number|null,
  incidents: number|null,
  unavailability: string|null
}

export const AvailabilityOverview: React.FC<
  AvailabilityOverviewProps
> = ({ incidents, availability, unavailability }) => {
	const color = 'secondary';
	const suffix = 'last 30 days';
	const metrics: StackedMetricFieldProps[] = [
		{
			label: 'Service Avaliability',
			value: availability ? availability : 'N/A',
			suffix: suffix,
			percent: true,
			color: color
		},
		{
			label: 'Incidents',
			value: incidents ? incidents : 'N/A',
			suffix: suffix,
			percent: false,
			color: color
		},
		{
			label: 'unavailability',
			value: unavailability ? unavailability : 'N/A',
			suffix: 'Total Service',
			percent: false,
			color: color
		}
	];
	return (
		<ServiceSnapshot
			metrics={metrics}
			buttonLabel='View Reports'
			buttonLink='reports/availability'
		/>
	);
};

export default withBlock(AvailabilityOverviewContainer, { heading: 'Availability'});
