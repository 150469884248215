import { createSelector, OutputSelector } from 'reselect';
import { PoorMarginalCallsState, PoorMarginalCallsReport } from './exports.types';
import { Datum, Serie } from '@nivo/scatterplot';

// Local state selectors.
const selectPoorMarginalCallsState = (state: PoorMarginalCallsState) => state === null ? null : state;

// Selects the report data from state.
export const selectPoorMarginalCallsReport = createSelector(
	selectPoorMarginalCallsState,
	state => state === null ? null  : state.report
);

// Mocks report data for local testing.
export const selectMockData = createSelector<PoorMarginalCallsState, any[]|null, PoorMarginalCallsReport[]>(
	selectPoorMarginalCallsReport,
	state => [
		{
			'timestamp': '2019-10-28 19:11:43',
			'sipDate': '2019-10-28',
			'direction': 'IN',
			'callTag': 822322,
			'mos': {
				'nVNet': {
					'in': 3.85,
					'out': null
				},
				'access': {
					'in': 4.41,
					'out': 3.35
				},
				'local': {
					'in': null,
					'out': null
				}
			},
			'loss': {
				'nVNet': {
					'in': 0.98,
					'out': null
				},
				'access': {
					'in': 0,
					'out': 1.82
				},
				'local': {
					'in': null,
					'out': null
				}
			}
		}
	]
);

// Selects report data with only MOS data attributes (flattened).
export const selectPoorMarginalCallsMOS = createSelector(
	selectPoorMarginalCallsReport,
	state => state === null ? null : state.map((item: PoorMarginalCallsReport) => {
		const {nVNet, access, local} = item.mos;

		const  flattened = {
			nvin: nVNet.in,
			nvout: nVNet.out,
			acin: access.in,
			acout: access.out,
			locin: local.in,
			locout: local.out,
		};

		return {
			timestamp: item.timestamp,
			sipDate: item.sipDate,
			callTag: item.callTag,
			...flattened,
		};
	})
);

// Selects report data with only LOSS data attributes (flattened).
export const selectPoorMarginalCallsLOSS = createSelector(
	selectPoorMarginalCallsReport,
	state => state === null ? null : state.map((item: PoorMarginalCallsReport) => {
		const {nVNet, access, local} = item.loss;

		const  flattened = {
			nvin: nVNet.in,
			nvout: nVNet.out,
			acin: access.in,
			acout: access.out,
			locin: local.in,
			locout: local.out,
		};

		return {
			timestamp: item.timestamp,
			sipDate: item.sipDate,
			callTag: item.callTag,
			...flattened,
		};
	})
);

// Selects report data with only the primary MOS offender present.
export const selectPoorMarginalCallsMOSOffender = createSelector(
	selectPoorMarginalCallsMOS,
	state => state === null ? null : state.map((item: any) => {
		const allowedMOSMetrics = ['nvin', 'nvout', 'acin', 'acout', 'locin', 'locout'];

		// Filters and sorts calls metrics as a list where the lowest value appears first.
		const [[lowestAttribute, lowestMetric]] = Object.entries(item)
			.filter(([key, value]: [string, any]) => allowedMOSMetrics.includes(key) && value !== null ? true : false)
			.sort((a, b) => {
				const [aKey, aValue] = a as [string, number];
				const [bKey, bValue] = b as [string, number];
				return (aValue < bValue) ? -1 : 1;
			});

		// Returns a modified report with only the primary MOS offender present.
		return {
			...item,
			offender: {
				id: lowestAttribute,
				value: lowestMetric,
			}
		};
	})
);

// Transforms the primary MOS offender report into required scatter plot serie format.
export const selectPoorMarginalCallsMOSSOffenderScatterplot = createSelector<PoorMarginalCallsState, any[]|null, Serie[]|null>(
	selectPoorMarginalCallsMOSOffender,
	state => {
		if (state === null) { return null; }

		// Local type definitions.
		type SegmentPlotMap = {
			[key: string]: Datum[]
		};

		const segmentPlotMap: SegmentPlotMap = state.reduce((acc, cur) => {
			const metricId = cur.offender.id;
			const formattedData = {
				x: cur.timestamp,
				y: cur.offender.value
			};
			
			return {
				...acc,
				[metricId]: [
					...acc[metricId],
					formattedData
				]
			};
		}, {
			'acin': [],
			'acout': [],
			'locin': [],
			'locout': [],
			'nvin': [],
			'nvout': [],
		});

		return Object.keys(segmentPlotMap)
			.map((item: any) => ({
				id: item,
				data: segmentPlotMap[item],
			}))
			.filter((item: any) => item.data.length); 
	});
