import * as React from 'react';
import { Route, RouteComponentProps, match } from 'react-router';
import { AxiosError } from 'axios';
import { createSelector } from 'reselect';
import { useOnDemandApi } from '@nexvortex/react-oapi';
import Skeleton from 'react-loading-skeleton';

import './styles.scss';

const AccountInfo: React.FC<RouteComponentProps> = ({match}) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	// Defines local hooks.
	const oapi = useOnDemandApi();

	// Defines local state.
	const [state, setState] = React.useState(null);

	// Defines local selectors.
	const selectAccount = (state: any) => state === null ? null : state;
	const selectCompanyDetails = createSelector(selectAccount, state => state === null ? null : state.company);
	const selectSites = createSelector(selectAccount, state => state === null ? null : state.sites.data);
	const selectPrimarySite = createSelector(selectSites, state => state === null ? null : state[0]);

	// Defines component lifecycle events.
	React.useEffect(() => {
		const fetchAccountDetails = async () => {
			try {
				if (!accountId || !(accountId.length > 0)) { throw new TypeError('A valid Account ID is required'); }

				const { data: { data: [ first ]}} = await oapi.http.get(
					`/v2/Accounts/${accountId}`
				);
				
				setState(first);
			}
			catch (error) {
				const result = error as AxiosError;
				console.error(result.message);
			}
		};

		fetchAccountDetails();
	}, [accountId]);

	const company = selectCompanyDetails(state);
	const site = selectPrimarySite(state);
	
	return (
		<dl className='account-info'>
			<div>
				<dt>Account:</dt>
				<dd>
					{ company
						? <span dangerouslySetInnerHTML={{ __html: `${company.name.toUpperCase()} (#${accountId})`}}/>
						: <Skeleton />
					}
				</dd>
			</div>
			<div>
				<dt>Site:</dt>
				<dd style={{height: '1.5em'}}>
					{ site 
						? `${site.name.toUpperCase()} (#${site.siteId})`
						: <Skeleton />
					}
				</dd>
			</div>
		</dl>
	);
};

const AccountInfoContainer = () => (
	<Route
		path='/secure/msip/:accountId'
		render={routeProps => <AccountInfo {...routeProps}/>}
	/>
);

export default AccountInfoContainer;
