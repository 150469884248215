import React from 'react';
const ExportIcon: React.FC = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85 102.1'>
		<g fill='#665A7F'>
			<g id='file-download'>
				<g>
					<path d='M82.5,36.8H59.6V2.5H25.4v34.3H2.5l40,40L82.5,36.8z M2.5,88.2v11.4h80V88.2H2.5z' />
					<path
						d='M82.5,102.1h-80c-1.4,0-2.5-1.1-2.5-2.5V88.2c0-1.4,1.1-2.5,2.5-2.5h80c1.4,0,2.5,1.1,2.5,2.5v11.4
				C85,101,83.9,102.1,82.5,102.1z M5,97.1h75v-6.4H5V97.1z M42.5,79.3c-0.6,0-1.3-0.2-1.8-0.7l-40-40c-0.7-0.7-0.9-1.8-0.5-2.7
				s1.3-1.5,2.3-1.5h20.4V2.5C22.9,1.1,24,0,25.4,0h34.3c1.4,0,2.5,1.1,2.5,2.5v31.8h20.4c1,0,1.9,0.6,2.3,1.5s0.2,2-0.5,2.7l-40,40
				C43.8,79,43.1,79.3,42.5,79.3z M8.5,39.3l34,34l34-34H59.6c-1.4,0-2.5-1.1-2.5-2.5V5H27.9v31.8c0,1.4-1.1,2.5-2.5,2.5H8.5z'
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default ExportIcon;
