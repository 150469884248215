import * as React from 'react';
import moment from 'moment';
import { Column, ExpandedChangeFunction } from 'react-table';

// Import shared Components.
import { AccountActivityData } from 'src/components/shared/hooks/useAccountActivity/exports.types';
import { DataTable } from 'src/components/shared/data-table';
import TableRowExpansion from './TableRowExpansion';

// Import local styles.
import 'react-table/react-table.css';
import './styles.scss';

export interface AccountActivityTableProps {
	isLoading: boolean,
  activity: AccountActivityData[] | null
}

export const Table: React.FC<AccountActivityTableProps> = ({isLoading, activity}) => {

	// Local State.
	const [expandedRow, setExpandedRow] = React.useState<{}|undefined>();
	const [filteredActivity, setFilteredActivity] = React.useState<AccountActivityData[]>([]);

	// Handles toggling row expansions.
	const handleRowExpansion: ExpandedChangeFunction = (column, event, isTouch) => {
		if (column[event] === false) {
			setExpandedRow({
				[event]: false
			});
		} else {
			setExpandedRow({
				[event]: true
			});
		}
	};

	// Handles filtering data results (Note: quick and dirty search, refactor in future).
	// const handleFilterResults = (needle: string) => {
	// 	// Return original activity when cleared.
	// 	if (activity && needle === '') {
	// 		return activity;
	// 	}

	// 	return filteredActivity.filter(row => {
	// 		return row.event.dateTime.includes(needle)
	// 			|| row.agent.type.includes(needle)
	// 			|| row.eventType.category.includes(needle)
	// 			|| row.eventType.name.includes(needle);
	// 	})
	// };

	// Defines data table column structure.
	const columns: Column[] = [
		{
			id: 'expand',
			show: true,
			expander: true,
			headerClassName: 'rt-expandable'
		},
		{
			Header: 'Event Date',
			id: 'eventDate',
			accessor: d => d.event.dateTime,
			Cell: ({value}) => moment(value).format('YYYY-MM-DD'),
		},
		{
			Header: 'Event Time',
			id: 'eventTime',
			accessor: d => d.event.dateTime,
			Cell: ({value}) => moment(value).format('HH:mm:ss')
		},
		{
			Header: 'Agent',
			id: 'agent',
			accessor: d => d.agent.type
		},
		{
			Header: 'Category',
			id: 'eventCategory',
			accessor: d => d.eventType.category
		},
		{
			Header: 'Activity',
			id: 'eventType',
			accessor: d => d.eventType.name
		},
	];	

	// Component lifecycle effects.
	React.useEffect(() => {
		if (activity && activity.length > 0) {
			setFilteredActivity(activity);
		}
	}, [isLoading, activity]);

	return (
		<>
			{/* <input onChange={e => setFilteredActivity(handleFilterResults(e.target.value)) } /> */}
			<DataTable
				data={filteredActivity || []}
				className="data-table--account-activity"
				columns={columns}
				loading={isLoading}
				defaultSorted={[{
					id: 'eventDate',
					desc: true,
				}]}
				filterable={false}
				expanded={expandedRow}
				expanderDefaults={{
					sortable: false,
					resizable: false,
					filterable: false,
				}}
				onExpandedChange={(column, event, isTouch,) => handleRowExpansion(column, event, isTouch)}
				pageSize={15}
				SubComponent={(props) => <TableRowExpansion {...props} /> }
			/>
		</>
	);
};

export default Table;