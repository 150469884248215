import * as React from 'react';
import { Row, Container, Col } from 'reactstrap';
import { match } from 'react-router';
import { PageComponentProps } from '@nexvortex/page';
import { CallQOSStatistics } from 'src/components/features/Blocks/CallQOSStatistics';
import QoSReportsNavigationToggle from '../../Blocks/QoSReportsNavigationToggle';

export interface CallQosStatisticsPageProps {}
 
const CallQosStatisticsPage: React.FC<CallQosStatisticsPageProps & PageComponentProps> = ({match}) => {
	
	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container
			fluid
			className="page page--msip-call-qos-statistics"
		>
			<Row tag="header">
				<Col lg={12}>
					<h2>CALL QOS STATISTICS</h2>
					<QoSReportsNavigationToggle currentPageTitle="QoS Statistics" />
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<CallQOSStatistics accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default CallQosStatisticsPage;