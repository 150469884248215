import * as React from 'react';
import { Alert } from 'reactstrap';

const GraphResultsMissing: React.FC<{}> = () => (
	<div className="d-flex justify-content-center" style={{paddingTop: 40, paddingBottom: 40, marginTop: 20, marginBottom: 20, backgroundColor: '#fafafa'}}>
		<Alert color="primary">
			<strong>Hmm...</strong> We were unable to retrieve any records for your query. Please try adjusting your date range filters.
		</Alert>
	</div>
);

export default GraphResultsMissing;