import React from 'react';
import VisibilityBarChart from '../VisibilityBarChart';
import { CallStatisticsReport } from 'src/components/shared/hooks/useCallStatistics/exports.types';
import { CallStatisticsTooltip } from 'src/components/shared/GraphTooltips';

export interface MarginalCallsProps {
	isLoading: boolean,
	data: Partial<CallStatisticsReport>[]|null,
	dateFrom: Date,
	dateTo: Date
}
 
const MarginalCalls: React.FC<MarginalCallsProps> = ({ isLoading, data, dateTo, dateFrom }) => {
	return (
		<VisibilityBarChart
			isLoading={isLoading}
			colors="rgba(255, 190, 101, .5)"
			data={data || []}
			dateFrom={dateFrom}
			dateTo={dateTo}
			graphTitle='Marginal Calls'
			graphTitleColor="rgba(255, 190, 101, 1)"
			keys={['marginal']}
			tooltip={props => <CallStatisticsTooltip {...props} />}
		/>
	);
};
 
export default MarginalCalls;