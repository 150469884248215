import React from 'react';
export const AverageQOSBySegmentIcon: React.FC = () => (
	<svg version='1.1' id='Layer_1' viewBox='0 0 410.1 468.5'>
		<g
			id='noun_Radar_Chart_2088830'
			transform='translate(-15 -9.986)'
			fill='#665A7F'
		>
			<g id='Group_1043' transform='translate(15.5 10.5)'>
				<path
					id='Path_556'
					d='M194.4,217.4V21.7c0-4.8,3.9-8.6,8.6-8.6c4.8,0,8.6,3.9,8.6,8.6v199.1l177-102.2
			c4.1-2.4,9.4-1,11.8,3.2c2.4,4.1,1,9.4-3.2,11.8L223.3,234.1l173.9,100.4c4.1,2.4,5.5,7.7,3.2,11.8c-2.4,4.1-7.7,5.5-11.8,3.2
			l-177-102.2v200.3c0,4.8-3.9,8.6-8.6,8.6c-4.8,0-8.6-3.9-8.6-8.6V250.8L23.5,349.5c-4.1,2.4-9.4,1-11.8-3.2
			c-2.4-4.1-1-9.4,3.2-11.8l0,0l173.9-100.4L14.9,133.6c-4.1-2.4-5.5-7.7-3.2-11.8s7.7-5.5,11.8-3.2l0,0L194.4,217.4z'
				/>
				<path
					id='Path_557'
					d='M198.7,5.6c2.7-1.5,6-1.5,8.7,0l192.9,110.9c2.7,1.5,4.3,4.4,4.3,7.5v220.8
			c0,3.1-1.7,6-4.4,7.5L207.3,461.8c-2.7,1.5-5.9,1.5-8.6,0L8.8,352.2c-2.7-1.5-4.3-4.4-4.3-7.5V124c0-3.1,1.6-5.9,4.3-7.5
			L198.7,5.6z M203.1,23.1L21.8,129v210.8l181.3,104.6l184.3-104.6V129L203.1,23.1z'
				/>
				<path
					id='Path_558'
					d='M203.5,66.4l143.6,84.2v167.3L203.5,401L62,317.9V150.6L203.5,66.4z M203.5,86.4l-124.2,74V308
			l124.2,73l126.3-73.1V160.4L203.5,86.4z'
				/>
				<path
					id='Path_559'
					d='M204,141.2l79.8,46.5v92.5l-79.8,46l-78.6-46v-92.4L204,141.2z M142.6,197.6v72.7l61.4,35.9
			l62.5-36v-72.6L204,161.2L142.6,197.6z'
				/>
			</g>
		</g>
	</svg>
);

export const CallQOSStatisticsIcon: React.FC = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 384'>
		<path
			fill='#665A7F'
			d='M332.8,256h38.4c6.4,0,12.8-6.4,12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4,0-12.8,6.4-12.8,12.8
	v134.4C320,249.6,326.4,256,332.8,256z M428.8,256h38.4c6.4,0,12.8-6.4,12.8-12.8V12.8C480,6.4,473.6,0,467.2,0h-38.4
	C422.4,0,416,6.4,416,12.8v230.4C416,249.6,422.4,256,428.8,256z M140.8,256h38.4c6.4,0,12.8-6.4,12.8-12.8v-70.4
	c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4,0-12.8,6.4-12.8,12.8v70.4C128,249.6,134.4,256,140.8,256z M236.8,256h38.4
	c6.4,0,12.8-6.4,12.8-12.8V44.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4,0-12.8,6.4-12.8,12.8v198.4C224,249.6,230.4,256,236.8,256z
	 M496,320H64V16c0-8.8-7.2-16-16-16H16C7.2,0,0,7.2,0,16v336c0,17.7,14.3,32,32,32h464c8.8,0,16-7.2,16-16v-32
	C512,327.2,504.8,320,496,320z'
		/>
	</svg>
);

export const QOSDriftReportIcon: React.FC = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 300'>
		<g fill='#665A7F' id='Group_1045' transform='translate(-904 -224)'>
			<path
				id='chart-line-solid'
				d='M1291.5,474H954V236.5c0-6.9-5.6-12.5-12.5-12.5l0,0h-25c-6.9,0-12.5,5.6-12.5,12.5
		c0,0,0,0,0,0V499c0,13.8,11.2,25,25,25h362.5c6.9,0,12.5-5.6,12.5-12.5l0,0v-25C1304,479.6,1298.4,474,1291.5,474L1291.5,474z
		 M1186.3,306.3l-57.3,57.4l-57.3-57.3c-9.8-9.8-25.6-9.8-35.3,0c0,0,0,0,0,0L982.7,360c-4.9,4.9-4.9,12.8,0,17.7c0,0,0,0,0,0
		l17.7,17.7c4.9,4.9,12.8,4.9,17.7,0c0,0,0,0,0,0l36-36l57.3,57.3c9.8,9.8,25.6,9.8,35.3,0c0,0,0,0,0,0l75-75L1186.3,306.3z'
			/>
			<g id='Rectangle_1451' transform='translate(936.895 230) rotate(45)'>
				<path
					d='M239.7-159.3H271c5.2,0,9.4,4.2,9.4,9.4v28.9c0,5.2-4.2,9.4-9.4,9.4h-31.2c-5.2,0-9.4-4.2-9.4-9.4v-28.9
			C230.3-155.1,234.5-159.3,239.7-159.3z'
				/>
				<path
					d='M277.6-114.4c-1.7,1.7-4,2.7-6.6,2.7h-31.2c-5.2,0-9.4-4.2-9.4-9.4v-28.9c0-5.2,4.2-9.4,9.4-9.4H271
			c5.2,0,9.4,4.2,9.4,9.4v28.9C280.3-118.4,279.3-116.1,277.6-114.4z M239.7-149.9l0,28.9l31.2,0v-28.9L239.7-149.9z'
				/>
			</g>
		</g>
	</svg>
);

export const TotalCallsReportIcon: React.FC = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 258.8 258.8'>
		<path
			fill='#665A7F'
			d='M230,0H28.8C12.9,0,0,12.9,0,28.8V230c0,15.8,12.9,28.8,28.8,28.8H230c15.8,0,28.8-12.9,28.8-28.8V28.8
    C258.8,12.9,245.8,0,230,0z M86.2,201.2H57.5V100.6h28.8V201.2z M143.8,201.2H115V57.5h28.8V201.2z M201.2,201.2h-28.8v-57.5h28.8
    V201.2z'
		/>
	</svg>
);

export const PoorCallsByDayOfWeekIcon: React.FC = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
		<path
			fill='#665A7F'
			d='M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z'
		/>
	</svg>
);

export const PoorCallsByDateIcon: React.FC = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
		<path
			fill='#665A7F'
			d='M0,464c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V192H0V464z M320,268c0-6.6,5.4-12,12-12h40
	c6.6,0,12,5.4,12,12v40c0,6.6-5.4,12-12,12h-40c-6.6,0-12-5.4-12-12V268z M320,396c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12v40
	c0,6.6-5.4,12-12,12h-40c-6.6,0-12-5.4-12-12V396z M192,268c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12v40c0,6.6-5.4,12-12,12h-40
	c-6.6,0-12-5.4-12-12V268z M192,396c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12v40c0,6.6-5.4,12-12,12h-40c-6.6,0-12-5.4-12-12V396z
	 M64,268c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12v40c0,6.6-5.4,12-12,12H76c-6.6,0-12-5.4-12-12V268z M64,396c0-6.6,5.4-12,12-12
	h40c6.6,0,12,5.4,12,12v40c0,6.6-5.4,12-12,12H76c-6.6,0-12-5.4-12-12V396z M400,64h-48V16c0-8.8-7.2-16-16-16h-32
	c-8.8,0-16,7.2-16,16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8,0-16,7.2-16,16v48H48C21.5,64,0,85.5,0,112v48h448v-48
	C448,85.5,426.5,64,400,64z'
		/>
	</svg>
);

export const PoorCallsByTimeOfDay: React.FC = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 496'>
		<path
			fill='#665A7F'
			d='M248,0C111,0,0,111,0,248s111,248,248,248s248-111,248-248S385,0,248,0z M305.1,350.1L216.9,286
	c-3.1-2.3-4.9-5.9-4.9-9.7V108c0-6.6,5.4-12,12-12h48c6.6,0,12,5.4,12,12v137.7l63.5,46.2c5.4,3.9,6.5,11.4,2.6,16.8l-28.2,38.8
	C318,352.8,310.5,354,305.1,350.1L305.1,350.1z'
		/>
	</svg>
);

export const PoorMarginalCallsIcon: React.FC = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 407.9 306'>
		<g fill='#665A7F' id='Group_1044' transform='translate(-994.006 -76.754)'>
			<path
				id='chart-bar-solid'
				d='M1389.2,331.7H1045V89.5c0-7-5.7-12.7-12.7-12.7h-25.5c-7,0-12.7,5.7-12.7,12.7
		c0,0,0,0,0,0v267.8c0,14.1,11.4,25.5,25.5,25.5h369.7c7,0,12.7-5.7,12.7-12.7l0,0v-25.5C1402,337.5,1396.3,331.7,1389.2,331.7
		C1389.2,331.7,1389.2,331.7,1389.2,331.7z'
			/>
			<ellipse
				id='Ellipse_407'
				cx='1148.7'
				cy='126.7'
				rx='26.4'
				ry='26.4'
			/>
			<ellipse
				id='Ellipse_408'
				cx='1122.3'
				cy='255.1'
				rx='26.4'
				ry='26.4'
			/>
			<ellipse
				id='Ellipse_409'
				cx='1224.2'
				cy='202.2'
				rx='26.4'
				ry='26.4'
			/>
			<ellipse
				id='Ellipse_410'
				cx='1326.1'
				cy='126.7'
				rx='26.4'
				ry='26.4'
			/>
			<ellipse
				id='Ellipse_411'
				cx='1303.5'
				cy='255.1'
				rx='26.4'
				ry='26.4'
			/>
		</g>
	</svg>
);
