import { createSelector } from 'reselect';
import { TotalCallsReport, TotalCallsState } from './exports.types';

// Local state selectors.
const selectTotalCallsState = (state: TotalCallsState) => state === null ? null : state;

export const selectTotalCallsReport = createSelector(
	selectTotalCallsState,
	state => state === null ? null  : state.report
);

export const selectTotalCallsByTotal= createSelector(
	selectTotalCallsReport,
	state => state === null ? null  : state.map(item => ({
		timestamp: item.groupDate,
		calls: item.calls.total,
	}))
);