import * as React from 'react';
import { match } from 'react-router';
import { PageComponentProps } from '@nexvortex/page';
import { Row, Container, Col } from 'reactstrap';
import QoSReportsNavigationToggle from 'src/components/features/Blocks/QoSReportsNavigationToggle';
import { PoorCallsByDayOfWeek } from 'src/components/features/Blocks/PoorCallsByDayOfWeek';

import '../styles.scss';

export interface PoorCallsByDayOfWeekPageProps {}

const PoorCallsByDayOfWeekPage: React.FC<
  PoorCallsByDayOfWeekPageProps & PageComponentProps
> = ({match}) => {

	// Extracts required parameters from route.
	const accountId = (match as match<{ accountId: string }>).params.accountId;

	return (
		<Container fluid className='page page--msip-poor-calls-by-day-of-week'>
			<Row tag='header'>
				<Col lg={12}>
					<h2>Poor Calls By Day of Week</h2>
					<QoSReportsNavigationToggle currentPageTitle='Poor - Week' />
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<PoorCallsByDayOfWeek accountId={accountId} />
				</Col>
			</Row>
		</Container>
	);
};

export default PoorCallsByDayOfWeekPage;
