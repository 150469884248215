import { createSelector } from 'reselect';
import { CallAverageBySegmentReport, CallAverageBySegmentState } from './exports.types';

// Local state selectors.
const selectCallAverageState = (state: CallAverageBySegmentState) => state === null ? null : state;

export const selectCallAverageReport = createSelector(
	selectCallAverageState,
	state => state === null ? null  : state.report
);

// TODO: Replace average/poor/total with business logic provided by Sri from derived data.
export const selectCallAverageReportWithSummary = createSelector(
	selectCallAverageReport,
	state => state === null ? null : state.map((report: CallAverageBySegmentReport) => ({
		...report,
		average: report.nVNet.in,
		poor: 0,
		total: 10,
	}))
);