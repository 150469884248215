import React from 'react';
import { Column } from 'react-table';
import moment from 'moment';

import { DataTable } from 'src/components/shared/data-table';
import { PoorCallsReport } from 'src/components/shared/hooks/usePoorCalls/exports.types';

export interface PoorCallsByDayOfWeekDataTableProps {
	isLoading: boolean,
	report: PoorCallsReport[],
	reactTableRef: React.RefObject<any>
}

const PoorCallsByDayOfWeekDataTable: React.SFC<
	PoorCallsByDayOfWeekDataTableProps
> = ({ isLoading, report, reactTableRef }) => {

	const tableColumns: Column[] = [
		{
			Header: 'Day',
			id: 'timestamp',
			accessor: d => moment(d.timestamp).format('dddd')
		},
		{
			Header: 'nV: In',
			id: 'nvin',
			accessor: d => d.nVNet.in,
		},
		{
			Header: 'nV: Out',
			id: 'nvout',
			accessor: d => d.nVNet.out
		},
		{
			Header: 'AC: In',
			id: 'acin',
			accessor: d => d.access.in
		},
		{
			Header: 'AC: Out',
			id: 'acout',
			accessor: d => d.access.out
		},
		{
			Header: 'Local: In',
			id: 'locin',
			accessor: d => d.local.in
		},
		{
			Header: 'Local: Out',
			id: 'locout',
			accessor: d => d.local.out
		}
	];

	return (
		<div className='pcb-day-off-week-data-table'>
			<DataTable
				data={report}
				className='data-table data-table--pcb-date'
				columns={tableColumns}
				defaultPageSize={30}
				showPagination={report.length > 30}
				loading={isLoading}
				reactTableRef={reactTableRef}
			/>
		</div>
	);
};

export default PoorCallsByDayOfWeekDataTable;
