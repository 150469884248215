import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, NavLink, Route } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { Portal, Page } from '@nexvortex/portal';
import MSIP from './components/features/Products/MSIP';
import AccountInfoContainer from './components/features/Blocks/AccountOverview';
import config from './config';

import './App.scss';
import { MSIPIcon, NVSMIcon } from './components/shared/icons';

const testAccountId = 17474;

const branding = (
	<>
		<NVSMIcon />
		<abbr title='nexVortex Service Monitor'>nVSM</abbr>
	</>
);

const navigation = (
	<Nav vertical navbar>
		<NavItem key='/secure/msip'>
			<Route
				path='/secure/msip/:accountId'
				render={({ match }) => (
          <>
						<NavLink className="mSIP" to={match.url}>
							<MSIPIcon />
							<span>mSIP</span>
						</NavLink>
            <Nav vertical navbar>
            	<NavLink to={`${match.url}/dashboard`}>Dashboard</NavLink>
            	<NavLink to={`${match.url}/reports/availability`}>Availability Reports</NavLink>
            	<NavLink to={`${match.url}/reports/qos`}>QOS Reports</NavLink>
            	<NavLink to={`${match.url}/reports/status`}>Status Reports</NavLink>
            	<NavLink to={`${match.url}/Logs`}>Logs</NavLink>
            	<NavLink to={`${match.url}/support`}>Support</NavLink>
            </Nav>
          </>
				)}
			/>
		</NavItem>
	</Nav>
);
 
const App: React.FC<{}> = () => {
	return (
		<Portal
			branding={branding} 
			navigation={navigation}
			authPortalUrl={config.authPortalUrl}
			redirectUri={`/secure/msip/${testAccountId}/dashboard`}
			header={<AccountInfoContainer />}
		>
			<Page
				strict
				path='/secure/msip'
				component={(routeProps: RouteComponentProps) => <MSIP {...routeProps} />}
			/>
			<Redirect exact strict from='/' to='/secure/msip' />
		</Portal>
	);
};

export default App;
