import * as React from 'react';

// Import local Components.
import LatencyGraph from './LatencyGraph';
import LatencyDataTable from './LatencyDataTable';

const LatencyContainer: React.SFC<{accountId: string|number}> = ({accountId}) => {
	return (
		<div className="latency-calls">
			<LatencyGraph isLoading={false} reports={[]} />
			<LatencyDataTable isLoading={false} reports={[]} />
		</div>
	);
};
 
export default LatencyContainer;