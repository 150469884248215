import { createSelector } from 'reselect';
import { CallStatisticsReport, CallStatisticsState } from './exports.types';

// Local state selectors.
const selectCallStatsState = (state: CallStatisticsState) => state === null ? null : state;

export const selectCallStatsReport = createSelector(
	selectCallStatsState,
	state => state === null ? null  : state.report
);

export const selectCallStatsReportWithSum = createSelector(
	selectCallStatsReport,
	state => state === null ? null : state.map((report: CallStatisticsReport) => ({
		...report,
		total: report.good + report.marginal + report.poor,
	}))
);