import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import moment from 'moment';

import { Block } from 'src/components/shared/Block';
import { usePoorCalls } from 'src/components/shared/hooks/usePoorCalls';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';
import { selectPoorCallsReport } from 'src/components/shared/hooks/usePoorCalls/exports.selectors';
import { ExportButton } from 'src/components/shared/buttons';
import { withExportHandler, ExportHOCProps, poorCallsByDateHeadersCSV, poorCallsByDateFilenameCSV } from 'src/components/shared/ExportHandler';
import PoorCallsByDateGraph from './PoorCallsByDateGraph';
import PoorCallsByDateDataTable from './PoorCallsByDateDataTable';

export interface PoorCallsByDateProps {
	accountId: string|number
}

const PoorCallsByDate: React.FC<PoorCallsByDateProps & ExportHOCProps> = ({ accountId, reactTableRef = useRef(null) }) => {

	// Local custom Hooks.
	const [poorCalls, fetchPoorCalls] = usePoorCalls({accountId});

	// Local contexts.
	const {state: {dateFrom, dateTo}} = React.useContext(CurrentReportContext);

	useEffect(() => {
		const fetchData = async () => {
			fetchPoorCalls({
				dateFrom: moment(dateFrom).toISOString(),
				dateTo: moment(dateTo).toISOString(),
				metricInterval: 86400,
				reportType: 'date',
			});
		};

		fetchData();
	}, [dateFrom, dateTo]);

	return (
		<Block
			header={{
				render: () =>
					<Container fluid className="p-0">
						<Row>
							<Col lg='12'>
								<Nav className="d-flex justify-content-end">
									<NavItem className="mr-3">
										<CurrentReportFilterForm siteFilter={{isVisible: false}} dateRangeSelector={{visibleOptions: ['lastMonth', 'lastWeek', 'lastDay']}} />
									</NavItem>
									<NavItem>
										<ExportButton
											reactTableRef={reactTableRef}
											headersCSV={poorCallsByDateHeadersCSV}
											filenameCSV={poorCallsByDateFilenameCSV}>EXPORT CSV</ExportButton>
									</NavItem>
								</Nav>
							</Col>
						</Row>
					</Container>
			}}
		>
			<div className='poor-calls-by-date'>
				<PoorCallsByDateGraph isLoading={poorCalls.isLoading} report={selectPoorCallsReport(poorCalls) || []} />
				<PoorCallsByDateDataTable isLoading={poorCalls.isLoading} report={selectPoorCallsReport(poorCalls) || []} reactTableRef={reactTableRef} />
			</div>
		</Block>
	);
};

export default withExportHandler(PoorCallsByDate);
