import * as React from 'react';

// Import shared Components.
import { withBlock } from 'src/components/shared/Block';
import { useAccountActivity } from 'src/components/shared/hooks/useAccountActivity';
import { selectAccountActivityData } from 'src/components/shared/hooks/useAccountActivity/exports.selectors';
import {default as AccountActivityTable} from './Table';

export interface AccountActivityTableContainerProps {
	accountId: number|string
}
 
const AccountActivityTableContainer: React.FC<AccountActivityTableContainerProps> = ({accountId}) => {
	// Local Custom Hooks.
	const [accountActivity, fetchAccountActivity, resetAccountActivity] = useAccountActivity({accountId});
	
	React.useEffect(() => {
		const fetchData = async () => {
			fetchAccountActivity({
				'per_page': 500
			});
		};

		fetchData();
	}, []);

	return (
		<AccountActivityTable isLoading={accountActivity.isLoading} activity={selectAccountActivityData(accountActivity)}/>
	);
};

export default withBlock(AccountActivityTableContainer, { heading: 'Account Activity' });