import * as React from 'react';
import { Column, ExpandedChangeFunction } from 'react-table';

// Import shared local Components.
import { DataTable } from 'src/components/shared/data-table';
import { SEERIncidentsReport } from 'src/components/shared/hooks/useSEERIncidents/exports.types';

// Import local styles.
import './styles.scss';
import TableRowExpansion from './TableRowExpansion';

export interface IncidentReportsDataTableProps {
  isLoading: boolean,
  report: SEERIncidentsReport[] | null
}

const IncidentReportsDataTable: React.SFC<IncidentReportsDataTableProps> = ({ isLoading, report }) => {
  
	// Local State.
	const [expandedRow, setExpandedRow] = React.useState<{}|undefined>();

	// Formats table cell classNames from a string.
	const formatCellClassname = (name: string) => `incident__${name}`;
  
	// Handles toggling row expansions.
	const handleRowExpansion: ExpandedChangeFunction = (column, event, isTouch) => {
		if (column[event] === false) {
			setExpandedRow({
				[event]: false
			});
		} else {
			setExpandedRow({
				[event]: true
			});
		}
	};

	// Table column defintions.
	const columns: Column<SEERIncidentsReport>[] = [
		{
			id: 'expand',
			show: true,
			expander: true,
			headerClassName: 'rt-expandable'
		},
		{
			Header: 'ID',
			id: 'id',
			accessor: d => d.outageId,
			className: formatCellClassname('id'),
			headerClassName: formatCellClassname('id'),
		},
		{
			Header: 'Title',
			id: 'title',
			accessor: d => d.incident.title,
			className: formatCellClassname('title'),
			headerClassName: formatCellClassname('title'),
		}, {
			Header: 'Start',
			id: 'dateStart',
			accessor: d => d.start,
			className: formatCellClassname('start-datetime'),
			headerClassName: formatCellClassname('start-datetime'),
		}, {
			Header: 'End',
			id: 'dateEnd',
			accessor: d => d.end,
			className: formatCellClassname('end-datetime'),
			headerClassName: formatCellClassname('end-datetime'),
		}, {
			Header: 'Category',
			id: 'category',
			accessor: d => d.incident.incidentCategory ? d.incident.incidentCategory.name : 'Unavailable',
			className: formatCellClassname('category'),
			headerClassName: formatCellClassname('category'),
		}, {
			Header: 'Source',
			id: 'source',
			accessor: d => d.outageReason ? d.outageReason.name : 'Unavailable',
			className: formatCellClassname('outage-reason'),
			headerClassName: formatCellClassname('outage-reason'),
		}, {
			Header: 'Description',
			id: 'description',
			accessor: d => d.incident.comment,
			className: formatCellClassname('comment'),
			headerClassName: formatCellClassname('comment'),
		}
	];

	return (
		<DataTable
			className="data-table--seer-incidents-report"
			data={report || []}
			columns={columns}
			filterable={false}
			loading={isLoading}
			noDataText='Great news! Zero incidents were reported for your service in the selected date range!'
			expanded={expandedRow}
			expanderDefaults={{
				sortable: false,
				resizable: false,
				filterable: false,
			}}
			onExpandedChange={(column, event, isTouch,) => handleRowExpansion(column, event, isTouch)}
			SubComponent={(props) => <TableRowExpansion {...props} /> }
		/>
	);
};

export default IncidentReportsDataTable;