import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export interface IconBlockItemProps {
  icon: () => React.ReactNode,
  label: string,
  link: string
}

const IconBlockItem: React.FC<IconBlockItemProps> = ({
	icon,
	label,
	link
}) => {
	return (
		<div className='icon-block-item'>
			<Link to={link} className='icon-block-item__wrapper'>
				<div className='icon-block-item__icon'>{icon()}</div>
			</Link>
			<Link to={link} className='icon-block-item__label'>
				{label}
			</Link>
		</div>
	);
};

export default IconBlockItem;
