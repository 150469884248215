import React from 'react';
import { Axis } from '@nivo/axes';
import { Theme } from '@nivo/core';
import { LegendProps } from '@nivo/legends';
import { ResponsiveScatterPlot, Serie } from '@nivo/scatterplot';

// Import local Components.
import { GraphResultsMissingAlert } from 'src/components/shared/Alerts';

// Import local styles.
import './styles.scss';
import GraphResultsLoading from 'src/components/shared/Alerts/GraphResultsLoading';

export interface PoorAndMarginalCallsGraphProps {
	isLoading: boolean,
	report: Serie[] | []
}

const PoorAndMarginalCallsGraph: React.FC<PoorAndMarginalCallsGraphProps> = ({isLoading, report}) => {

	// Defines graph options/settings.
	const axisBottom: Axis = {
		orient: 'bottom',
		tickSize: 5,
		tickPadding: 10,
		tickRotation: 0,
		format: '%m/%d %H:%M',
	};

	const axisLeft: Axis = {
		orient: 'left',
		tickSize: 0,
		tickPadding: 15,
		tickRotation: 0,
		tickValues: 8
	};

	const legends: ({
		dataFrom: 'keys' | 'indexes'
	} & LegendProps)[] = [
		{
			dataFrom: 'keys',
			anchor: 'top-left',
			direction: 'row',
			justify: false,
			itemWidth: 75,
			itemHeight: 10,
			itemsSpacing: 10,
			symbolSize: 10,
			symbolSpacing: 10,
			symbolShape: 'circle',
			translateX: -12,
			translateY: -40,
		}
	];

	const theme: Theme = {
		legends: {
			text: {
				fontSize: 12,
				fontFamily: '\'Avenir\', sans-serif',
				fontWeight: 900,
				textTransform: 'uppercase',
			}
		}
	};

	const xScale: any = {
		type: 'time',
		format: '%Y-%m-%d %H:%M:%S', // Uses D3.js timeformat
		precision: 'second',
	};

	const yScale: any = {
		type: 'linear',
		stacked: false,
		min: 0,
		max: 5,
	};

	if (isLoading) {
		return <GraphResultsLoading />;
	}

	if (!report.length) {
		return <GraphResultsMissingAlert />;
	}

	return (
		<div className='graph graph--poor-and-marginal-calls' >
			<ResponsiveScatterPlot
				data={report}
				margin={{ top: 60, right: 20, bottom: 40, left: 40 }}
				blendMode='normal'
				nodeSize={8}
				xFormat="time:%Y-%m-%d %H:%M:%s"
				axisBottom={axisBottom}
				axisLeft={axisLeft}
				colors={
					[
						'rgba(78, 167, 218, 1)',
						'rgba(255, 140, 105, 1)',
						'rgba(151, 153, 155, 1)',
						'rgba(255, 190, 101, 1)',
						'rgba(102, 90, 127, 1)',
						'rgba(66, 149, 119, 1)',
					]
				}
				enableGridX={false}
				enableGridY={false}
				theme={theme}
				legends={legends}
				xScale={xScale}
				yScale={yScale}
			/>
		</div >
	);
};

export default PoorAndMarginalCallsGraph;
