import React, { useContext, useEffect, useState, useCallback, useReducer } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useOnDemandApi } from '@nexvortex/react-oapi';
import { AlertContext } from '@nexvortex/portal';

import { PoorMarginalCallsHook, PoorMarginalCallsArgs, PoorMarginalCallsState } from './exports.types';
import { reducer } from './exports.reducer';

export const usePoorMarginalCalls: PoorMarginalCallsHook = ({accountId}) => {

	// Defines local Contexts.
	const oapi = useOnDemandApi();
	const alerts = useContext(AlertContext);
  
	// Defines local State.
	const initialState: PoorMarginalCallsState = {
		isLoading: false,
		error: null,
		report: null,
		reportingPeriod: null,
	};
	const [state, dispatch] = useReducer(reducer, initialState);
	const [requestArgs, setRequestArgs] = useState<PoorMarginalCallsArgs|null>(null);

	// Defines local Components.
	const ErrorDetails: React.FC<{response: AxiosResponse<any>|null}> = ({response}) => {
		const unavailable = 'Response Details Unavailable';

		if (response) {
			return (
				<pre>
					{ response && response.data
						? JSON.stringify(response.data, null, 2)
						: unavailable
					}
				</pre>
			);
		}

		return (
			<pre>{unavailable}</pre>
		);
	};

	// Exposed memoized function for retrieving Call Statistics.
	const fetchReport = useCallback( async (args: PoorMarginalCallsArgs) => {

		// Setup arguments to be used with memoization.
		setRequestArgs(args);

		try {
			if (alerts == null) throw Error('Alerts Context required.');

			// Prepare Call Statistics request.
			let resource = `/v2/mSIP/${accountId}/Reports/Quality/Calls/PoorAndMarginal`;
			if (args !== null) {
				const querystring = Object.keys(args)
					.map(key => key + '=' + args[key])
					.join('&');
				resource = `${resource}?${querystring}`;
			}
			
			// Dispatch Call Statistics request and set response in state.
			dispatch({type: 'LOADING_REPORT_DATA', value: true});
			const {data: { data }} = await oapi.http.get(resource);
			dispatch({type: 'UPDATE_REPORT_DATA', value: data.report});
		} catch (error) {
			const result = error as AxiosError;
			dispatch({type: 'ERROR_LOADING_REPORT_DATA', value: result});
		}
	}, [accountId, requestArgs]);

	// Exposed function for resetting Call Statistics.
	const resetReport = () => {
		dispatch({type: 'RESET_REPORT_DATA', value: true});
	};

	// Lifecycle method reponsible for error handling.
	useEffect(() => {
		if (alerts !== null && state.error !== null) {
			const {name, message, response} = state.error as AxiosError;
			alerts.show(message, {
				timeout: 5000,
				type: 'danger',
				title: `Request Status - ${name}`,
				details: <ErrorDetails response={ response ? response : null} />
			});
		}
	}, [state.error]);

	return [ state, fetchReport, resetReport ];
};