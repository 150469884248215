import * as React from 'react';
import moment from 'moment';
import { DataTable } from 'src/components/shared/data-table';
import { CallAverageBySegmentReport } from 'src/components/shared/hooks/useCallAverageBySegment/exports.types';
import { Column, TableCellRenderer } from 'react-table';

export interface AverageBySegmentTableProps {
	isLoading: boolean,
  data: CallAverageBySegmentReport[] | null
}
 
const AverageBySegmentTable: React.SFC<AverageBySegmentTableProps> = ({isLoading, data}) => {

	// Custom cell renderer for the timestamp attribute.
	const CellTimestamp: TableCellRenderer = ({value}) => {
		const date = moment(value);
		return (
      <>
        <span className="datetime__date">{date.format('YYYY/MM/DD')}</span>
        <span className="datetime__time" style={{marginLeft: '1ch', opacity: .5}}>{date.format('HH:mm:ss')}</span>
      </>
		);
	};
  
	// Custom cell renderer for all MOS quality metrics.
	const CellMOSMetric: TableCellRenderer = ({value}) => {
		if (!value) { return 0; }
		const metric = value as number;
		return metric.toFixed(2);
	};
  
	// Data table column definitions.
	const columns: Column<CallAverageBySegmentReport>[] = [
		{
			Header: 'Date',
			id: 'timestamp',
			accessor: d => d.timestamp,
			Cell: CellTimestamp
		},
		{
			Header: 'NVNET: IN',
			id: 'nvnet_in',
			accessor: d => d.nVNet.in,
			Cell: CellMOSMetric,
		},
		{
			Header: 'NVNET: OUT',
			id: 'nvnet_out',
			accessor: d => d.nVNet.out,
			Cell: CellMOSMetric,
		},
		{
			Header: 'Access: In',
			id: 'access_in',
			accessor: d => d.access.in,
			Cell: CellMOSMetric,
		},
		{
			Header: 'Access: Out',
			id: 'access_out',
			accessor: d => d.access.out,
			Cell: CellMOSMetric,
		},
		{
			Header: 'Local: In',
			id: 'local_in',
			accessor: d => d.local.in,
			Cell: CellMOSMetric,
		},
		{
			Header: 'Local: Out',
			id: 'local_out',
			accessor: d => d.local.out,
			Cell: CellMOSMetric,
		},
	];
  
	return (
		<DataTable
			className="data-table--qos-average-by-segment"
			data={data || []}
			columns={columns}
			filterable={false}
			loading={isLoading}
		/>
	);
};
 
export default AverageBySegmentTable;