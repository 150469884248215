import React from 'react';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import { LegendProps } from '@nivo/legends';

// Import local Components.
import { PoorCallsReport } from 'src/components/shared/hooks/usePoorCalls/exports.types';
import { PoorCallsTooltip } from 'src/components/shared/GraphTooltips';

// Import local styles.
import './styles.scss';
import { GraphResultsMissingAlert, GraphResultsLoadingAlert } from 'src/components/shared/Alerts';

export interface PoorCallsByDateGraphProps {
	isLoading: boolean,
	report: PoorCallsReport[]
}

export interface PoorCallsByDateGraphDataFormat {
	timestamp: Date,
	nvin: number,
	nvout: number,
	acin: number,
	acout: number,
	locin: number,
	locout: number
}

const PoorCallsByDateGraph: React.FC<PoorCallsByDateGraphProps> = ({ isLoading, report }) => {

	// Defines graph legend strcuture.
	const legends: ({
    dataFrom: 'keys' | 'indexes'
	} & LegendProps)[] = [
		{
			dataFrom: 'keys',
			anchor: 'top-left',
			direction: 'row',
			justify: false,
			itemWidth: 75,
			itemHeight: 10,
			itemsSpacing: 10,
			symbolSize: 10,
			symbolSpacing: 10,
			symbolShape: 'circle',
			translateX: -12,
			translateY: -40,
		}
	];

	// Transforms report data into graph format.
	const prepareGraphData = ( report: PoorCallsReport[] ): PoorCallsByDateGraphDataFormat[] => {
		return report.map((item: PoorCallsReport, index) => {
			return {
				timestamp: item.timestamp,
				nvin: item.nVNet.in,
				nvout: item.nVNet.out,
				acin: item.access.in,
				acout: item.access.out,
				locin: item.local.in,
				locout: item.local.out,
			};
		});
		
	};

	if (isLoading) {
		return <GraphResultsLoadingAlert />;
	}

	if (report && !report.length) {
		return <GraphResultsMissingAlert />;
	}
	
	return (
		<div className='graph graph--poor-calls-by-date'>
			<ResponsiveBar
				data={prepareGraphData(report)}
				animate={true}
				axisLeft={{
					tickSize: 0,
					tickValues: 4,
				}}
				axisBottom={{
					tickPadding: 10,
					format: d => moment(d).format('MM/DD'),
					tickSize: 5,
				}}
				borderRadius={3}
				enableLabel={false}
				enableGridY={false}
				groupMode='grouped'
				indexBy='timestamp'
				keys={['nvin', 'nvout', 'acin', 'acout', 'locin', 'locout']}
				legends={legends}
				margin={{ top: 40, right: 0, bottom: 80, left: 40 }}
				maxValue='auto'
				motionStiffness={90}
				motionDamping={15}
				minValue={0}
				padding={0}
				theme={{
					legends: {
						text: {
							fontSize: 12,
							fontFamily: '\'Avenir\', sans-serif',
							fontWeight: 900,
							textTransform: 'uppercase',
						}
					}
				}}
				colors={
					[
						'rgba(78, 167, 218, 1)',
						'rgba(255, 140, 105, 1)',
						'rgba(151, 153, 155, 1)',
						'rgba(255, 190, 101, 1)',
						'rgba(102, 90, 127, 1)',
						'rgba(102, 90, 127, 1)',
					]
				}
				tooltip={(props) => (<PoorCallsTooltip {...props} />)}
			/>
		</div>
	);
};

export default PoorCallsByDateGraph;
