import React from 'react';
import VisibilityBarChart from '../VisibilityBarChart';
import { CallStatisticsReport } from 'src/components/shared/hooks/useCallStatistics/exports.types';
import { CallStatisticsTooltip } from 'src/components/shared/GraphTooltips';

export interface PoorCallsProps {
	isLoading: boolean,
	data: Partial<CallStatisticsReport>[] | {}[] | null,
	dateFrom: Date,
	dateTo: Date
}
 
const PoorCalls: React.FC<PoorCallsProps> = ({ isLoading, data, dateFrom, dateTo }) => {
	return (
		<VisibilityBarChart
			isLoading={isLoading}
			colors="rgba(202, 80, 87, .5)"
			data={data || []}
			dateFrom={dateFrom}
			dateTo={dateTo}
			graphTitle='Poor Calls'
			graphTitleColor="rgba(202, 80, 87, 1)"
			keys={['poor']}
			tooltip={props => <CallStatisticsTooltip {...props} />}
		/>
	);
};
 
export default PoorCalls;