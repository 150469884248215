import React, { useEffect } from 'react';
import moment from 'moment';

// Import local Components.
import { withBlock } from 'src/components/shared/Block/withBlock';
import { useCallStatistics } from 'src/components/shared/hooks/useCallStatistics';
import { selectCallStatsReportWithSum } from 'src/components/shared/hooks/useCallStatistics/exports.selectors';
import { CurrentReportContext, CurrentReportFilterForm } from 'src/components/shared/context/CurrentReport';
import { CallQOSStatisticsGraph } from './CallQOSStatisticsGraph';
import { CallQOSStatisticsDataTable } from './CallQOSStatisticsDataTable';

// Import local styles.
import './styles.scss';

export interface CallQOSStatisticsProps {
	accountId: string | number
}

const CallQOSStatistics: React.SFC<CallQOSStatisticsProps> = ({accountId}) => {
	// Local Custom Hooks.
	const [ callStats, fetchCallStats, resetCallStats] = useCallStatistics({ accountId });

	// Local Contexts.
	const { state: { dateFrom, dateTo, metricInterval }} = React.useContext(CurrentReportContext);
	
	// Component Lifecycle Methods.
	useEffect(() => {
		fetchCallStats({
			dateFrom: moment(dateFrom).toISOString(),
			dateTo: moment(dateTo).toISOString(),
			metricInterval: metricInterval,
		});
	}, [dateFrom, dateTo, metricInterval]);

	return (
		<div className='call-qos-statistics'>
			<CallQOSStatisticsGraph isLoading={callStats.isLoading} data={selectCallStatsReportWithSum(callStats)} />
			<CallQOSStatisticsDataTable isLoading={callStats.isLoading} data={selectCallStatsReportWithSum(callStats)} />
		</div>
	);
};

export default withBlock(CallQOSStatistics, {
	header: {
		heading: 'Call Count by QOS',
		actions: [ <CurrentReportFilterForm siteFilter={{isVisible: false}} /> ]
	}
});
