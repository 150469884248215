import React from 'react';
import { Column, TableCellRenderer } from 'react-table';
import moment from 'moment';
import { DataTable } from 'src/components/shared/data-table';
import { CallDriftReport } from 'src/components/shared/hooks/useCallDrift/exports.types';

import 'react-table/react-table.css';
import './styles.scss';

export interface CallDriftDataTableProps {
	isLoading: boolean,
  data: CallDriftReport[] | null
}

// Custom cell renderer for the timestamp field.
const CellTimestamp: TableCellRenderer = ({value}) => {
	const date = moment(value);
	return (
		<>
			<span className="datetime__date">{date.format('YYYY/MM/DD')}</span>
			<span className="datetime__time" style={{marginLeft: '1ch', opacity: .5}}>{date.format('HH:mm:ss')}</span>
		</>
	);
};

// Custom cell renderer for the 30 Day average field.
const CellAggregatedAverage: TableCellRenderer = ({value}) => {
	if(isNaN(value)) { return value; }
	const average = value.toFixed(2);
	return `${average}%`;
};

const CallDriftDataTable: React.FC<
  CallDriftDataTableProps
> = ({ isLoading, data }) => {

	const columns: Column<CallDriftReport>[] = [
		{
			Header: 'Date',
			id: 'sipDate',
			accessor: d => d.sipDate,
			Cell: CellTimestamp
		},
		{
			Header: 'Calls',
			id: 'total',
			accessor: d => d.totalCallsCount
		},
		{
			Header: 'Poor',
			id: 'poor',
			accessor: d => d.poorCallsCount,
		},
		{
			Header: '30 Day',
			id: 'aggregatedAverage',
			accessor: d => d.poorCalls30DayAverageAgg ? d.poorCalls30DayAverageAgg.poorCallAverage : '(Unavailable)',
			Cell: CellAggregatedAverage
		}
	];
	
	return (
		<DataTable
			className="data-table--call-drift"
			columns={columns}
			data={data || []}
			defaultPageSize={15}
			pageSize={15}
			loading={isLoading}
		/>
	);
};

export default CallDriftDataTable;
