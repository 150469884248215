import React from 'react';
import moment from 'moment';
import { BarExtendedDatum } from '@nivo/bar';

import './styles.scss';

const CallStatisticsTooltip: React.FC<BarExtendedDatum> = ({color, data, id, ...rest}) => {
	return (  
		<div className="custom-tooltip custom-tooltip--call-statistics">
			{ data.timestamp && 
				<div className="tooltip__timestamp" style={{color}}>
					<h5>{ moment(data.timestamp).format('MMMM DD, YYYY') }</h5>
					<h6>{ moment(data.timestamp).format('HH:mm:ss') }</h6>
				</div>
			}
			<div className="tooltip__metrics">
				<div className="metric metric--target">
					<span className="metric__label">{id}</span>
					<span className="metric__value" style={{color}}>{data[id]}</span>
				</div>

				{ data.total && 
					<div className="metric metric--aggregate">
						<span className="metric__label">Total:</span>
						<span className="metric__value" style={{color}}>{data.total}</span>
					</div>
				}
			</div>
		</div>
	);
};

export default CallStatisticsTooltip;